import { Table, TableContainer, Tbody, Td, Th, Thead, Tr, Link, useDisclosure } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import UserPhoneUpdate from './update'
import UserPhoneDetail from './detail'

// Services
import { userPhoneServices } from '../../../services/users/userPhone/index'

// Components
import CustomModal from '../../../components/modal/CustomModal'
import ActionButton from '../../../components/customFormElements/ActionButtons'

const UserPhone = () => {
  const [userPhoneData, setUserPhonesData] = useState<any[]>([])
  const [userPhoneId, setUserPhoneId] = useState<string>()

  const { isOpen: isOpenUpdate, onOpen: onOpenUpdate, onClose: onCloseUpdate } = useDisclosure()
  const { isOpen: isOpenDetail, onOpen: onOpenDetail, onClose: onCloseDetail } = useDisclosure()

  const id = location.pathname.split('/user/')[1]

  useEffect(() => {
    if (!isOpenUpdate) {
      setUserPhone()
    }
    userPhoneServices.findByUserId(id).then((response) => {
      if (response) {
        setUserPhonesData(response.data)
      } else {
        toast('An error occurred while loading the Phone data.')
      }
    })
  }, [isOpenUpdate, id])

  const setUserPhone = () => {
    userPhoneServices.findByUserId(id).then((response) => {
      if (response) {
        setUserPhonesData(response.data)
      } else {
        toast('An error occurred while loading the Phone data.')
      }
    })
  }

  const updateUserPhone = (phoneId: string) => {
    setUserPhoneId(phoneId)
    onOpenUpdate()
  }

  const detailUserPhone = (phoneId: string) => {
    setUserPhoneId(phoneId)
    onOpenDetail()
  }

  const deleteUserPhone = (id: string) => {
    userPhoneServices.remove(id).finally(() => {
      setUserPhone()
    })
  }

  return (
    <>
      <TableContainer maxH='20em' overflowY='auto'>
        <Table variant='simple' size={'sm'}>
          <Thead bg={'white'} position={'sticky'} top={'0px'} zIndex={1}>
            <Tr>
              <Th children={'Country'} />
              <Th children={'Phone Number'} />
              <Th children={'Action'} />
            </Tr>
          </Thead>
          {userPhoneData.map((item) => {
            return (
              <Tbody paddingBottom={'20px'} key={item.id}>
                <Tr>
                  <Td
                    onClick={() => detailUserPhone(item.id)}
                    cursor={'pointer'}
                    color={'blue'}
                    children={<Link children={item.country} />}
                  />
                  <Td children={item.phoneNumber} />
                  <Td
                    children={
                      <ActionButton deleteFunction={deleteUserPhone} id={item.id} updateFunction={updateUserPhone} />
                    }
                  />
                </Tr>
              </Tbody>
            )
          })}
        </Table>
      </TableContainer>
      {
        <CustomModal
          title='User Phone Update'
          isOpen={isOpenUpdate}
          onClose={onCloseUpdate}
          key={userPhoneId}
          children={<UserPhoneUpdate id={userPhoneId ? userPhoneId : ''} closeModal={onCloseUpdate} />}
        />
      }
      {
        <CustomModal
          title='User Phone Detail'
          isOpen={isOpenDetail}
          onClose={onCloseDetail}
          key={'User_Phone_Detail'}
          children={<UserPhoneDetail id={userPhoneId ? userPhoneId : ''} />}
        />
      }
    </>
  )
}

export default UserPhone
