import { Grid, GridItem, Input } from '@chakra-ui/react'
import { useEffect, useState } from 'react'

// Components
import CountrySelect from '../selects/CountrySelect'
import ProjectSelect from '../selects/ProjectSelect'

interface ArrayObjectSelectState {
  setSearchInput: (searchTag: any) => void
  setSelectedCountry: (country: any) => void
  setSelectedProject: (project: any) => void
}

const EmployeeFilter: React.FC<ArrayObjectSelectState> = ({ setSearchInput, setSelectedCountry }) => {
  const [inputValue, setInputValue] = useState('')
  const [country, setCountry] = useState<any | null>(null)
  const [project, setProject] = useState<any | null>(null)

  useEffect(() => {
    setSearchInput(inputValue)
    setSelectedCountry(country)
    setProject(project)
  }, [opener, inputValue, country, project])

  return (
    <Grid templateRows='repeat(1, 1fr)' templateColumns='repeat(7, 1fr)' gap={2}>
      <GridItem
        colSpan={3}
        children={
          <Input bg={'white'} type='text' placeholder='Search...' onChange={(e) => setInputValue(e.target.value)} />
        }
      />
      <GridItem
        colSpan={2}
        children={<CountrySelect selectCountry={''} setSelectedCountry={setCountry} selectedCountry={country} />}
      />

      <GridItem colSpan={2} children={<ProjectSelect selectedProject={project} setSelectedProject={setProject} />} />
    </Grid>
  )
}

export default EmployeeFilter
