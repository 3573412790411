import { TableContainer, Table, Tbody, Thead, Td, Th, Tr } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

// Services
import { messageLogServices } from '../../../services/messageLog'

import LoadingComponent from '../../shareds/LoadingComponent'

// Components

const MessageLogTable = () => {
  const [employeeCallData, setEmployeeCallData] = useState<any[]>([])

  useEffect(() => {
    messageLogServices.dailyMessageLog().then((response) => {
      if (response) {
        setEmployeeCallData(response.data)
      } else {
        toast('An error occurred while loading the  data.')
      }
    })
  }, [])

  if (!employeeCallData) {
    return <LoadingComponent />
  }

  return (
    <>
      <TableContainer maxH='40em' overflowY='auto'>
        <Table variant='simple' size={'sm'}>
          <Thead bg={'white'} position={'sticky'} top={'0px'} zIndex={1}>
            <Tr>
              <Th children={'Agent Name'} />
              <Th children={'Recipient'} />
              <Th children={'Status'} />
              <Th children={'Parts'} />
              <Th children={'Cost'} />
              <Th children={'Created Date'} />
            </Tr>
          </Thead>
          {employeeCallData.map((item) => {
            return (
              <Tbody paddingBottom={'20px'} key={item.id}>
                <Tr>
                  <Td children={item.originator} fontWeight={'bold'} />
                  <Td children={item.recipient} />
                  <Td children={item.status} />
                  <Td children={item.parts} />
                  <Td children={item.cost} />
                  <Td children={item.createdDate} />
                </Tr>
              </Tbody>
            )
          })}
        </Table>
      </TableContainer>
    </>
  )
}

export default MessageLogTable
