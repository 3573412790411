// import { axiosInstance } from '../../axios'

// export const userMessageServices = {
//   create: (requestModel: any) => {
//     return axiosInstance.post('user-messages', requestModel)
//   },
//   update: (id: string, requestModel: any) => {
//     return axiosInstance.put(`user-messages/${id}`, requestModel)
//   },
//   findAll: () => {
//     return axiosInstance.get('user-messages')
//   },
//   dailyMessageCountPerAgent: () => {
//     return axiosInstance.get('user-messages/dailyMessageCountPerAgent')
//   },
//   dailyMessageCountPerAgentExport: () => {
//     return axiosInstance.get('user-messages/dailyMessageCountPerAgentExport', { responseType: 'blob' })
//   },
//   findOne: (id: string) => {
//     return axiosInstance.get(`user-messages/${id}`)
//   },
//   findByUserId: (id: string) => {
//     return axiosInstance.get(`user-messages/findByUserId/${id}`)
//   },
//   findByEmployeeId: (id: string) => {
//     return axiosInstance.get(`user-messages/findByEmployeeId/${id}`)
//   },
//   remove: (id: string) => {
//     return axiosInstance.delete(`user-messages/${id}`)
//   },
// }

import { axiosInstance } from '../../axios'

export const userMessageServices = {
  create: async (requestModel: any) => {
    return await axiosInstance.post('user-messages', requestModel)
  },
  update: async (id: string, requestModel: any) => {
    return await axiosInstance.put(`user-messages/${id}`, requestModel)
  },
  findAll: async () => {
    return await axiosInstance.get('user-messages')
  },
  dailyMessageCountPerAgent: async () => {
    return await axiosInstance.get('user-messages/dailyMessageCountPerAgent')
  },
  dailyMessageCountPerAgentExport: async () => {
    return await axiosInstance.get('user-messages/dailyMessageCountPerAgentExport', { responseType: 'blob' })
  },
  findOne: async (id: string) => {
    return await axiosInstance.get(`user-messages/${id}`)
  },
  findByUserId: async (id: string) => {
    return await axiosInstance.get(`user-messages/findByUserId/${id}`)
  },
  findByEmployeeId: async (id: string) => {
    return await axiosInstance.get(`user-messages/findByEmployeeId/${id}`)
  },
  remove: async (id: string) => {
    return await axiosInstance.delete(`user-messages/${id}`)
  },
}
