import { axiosInstance } from '../../axios'

export const interestingMatcheServices = {
  create: (requestModel: any) => {
    return axiosInstance.post('interesting-matches', requestModel)
  },
  update: (id: string, requestModel: any) => {
    return axiosInstance.put(`interesting-matches/${id}`, requestModel)
  },
  findAll: (projectId: string) => {
    return axiosInstance.get(`interesting-matches/findByProjectId/${projectId}`)
  },
  findOne: (id: string) => {
    return axiosInstance.get(`interesting-matches/${id}`)
  },
  remove: (id: string) => {
    return axiosInstance.delete(`interesting-matches/${id}`)
  },
}
