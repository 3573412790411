import { ReactNode } from 'react'
import {
  Box,
  CloseButton,
  Flex,
  useColorModeValue,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  BoxProps,
  Image,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionIcon,
  AccordionPanel,
} from '@chakra-ui/react'
import { FiHome } from 'react-icons/fi'
import { FaLanguage } from 'react-icons/fa'
import { MdWork, MdAccountBox, MdOutlineManageAccounts, MdDescription, MdOutlineWifiCalling3 } from 'react-icons/md'
import { GiLevelEndFlag, GiWorld } from 'react-icons/gi'
import { IoFootballSharp } from 'react-icons/io5'
import { IconType } from 'react-icons'
import SearchBar from './SearchBar'
import SideBarItem from './SideBarItem'

interface LinkItemProps {
  name: string
  icon: IconType
  to: string
}
const LinkItems: Array<LinkItemProps> = [
  { name: 'Dashboard', icon: FiHome, to: '/' },
  { name: 'Users', icon: MdAccountBox, to: '/user' },
  { name: 'Manage Team', icon: MdOutlineManageAccounts, to: '/manage-employee' },
  { name: 'Employee Call', icon: MdOutlineWifiCalling3, to: '/employee-call' },
]

const CommonItems: Array<LinkItemProps> = [
  { name: 'Project', icon: MdWork, to: '/project' },
  { name: 'Language', icon: FaLanguage, to: '/language' },
  { name: 'Message Template', icon: GiLevelEndFlag, to: '/message-template' },
  { name: 'Interesting Matche', icon: IoFootballSharp, to: '/interesting-matche' },
  { name: 'Mail Template', icon: MdDescription, to: '/mail-template' },
  // { name: 'Country', icon: GiWorld, to: '/country' },
]

export default function DefaultLayout({ children }: { children: ReactNode }) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Box minH='100vh' bg={useColorModeValue('white.100', 'white.900')}>
      <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement='left'
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size='full'
      >
        <DrawerContent children={<SidebarContent onClose={onClose} />} />
      </Drawer>

      <SearchBar onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p='0' children={children} />
    </Box>
  )
}

interface SidebarProps extends BoxProps {
  onClose: () => void
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  return (
    <Box
      transition='3s ease'
      bg={useColorModeValue('white', 'gray.900')}
      borderRight='1px'
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos='fixed'
      h='full'
      {...rest}
    >
      <Flex h='20' alignItems='center' mx='8' justifyContent='space-between' marginBottom={'15px'}>
        <Text fontSize='5xl' fontFamily='monospace' fontWeight='bold' color='red'>
          <Image src='https://i.ibb.co/TPqRcdJ/callcentercrmlogo-copy-removebg-preview.png' alt='CallCenter' />
        </Text>
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      {LinkItems.map((link) => (
        <SideBarItem key={link.name} icon={link.icon} to={link.to} children={link.name} />
      ))}

      <Accordion defaultIndex={[0]} allowMultiple>
        <AccordionItem>
          <AccordionButton pl={'70px'}>
            <Box as='span' flex='1' textAlign='left' children={'Common'} />
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel pl={-20}>
            {CommonItems.map((link) => (
              <SideBarItem key={link.name} icon={link.icon} to={link.to} children={link.name} />
            ))}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  )
}
