import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Box, Card, CardBody, Grid, GridItem, Heading } from '@chakra-ui/react'

import DefaultLayout from '../../../../layouts/DefaultLayout'

import { MdQuestionAnswer } from 'react-icons/md'

// Services
import { messageTemplateServices } from '../../../../services/common/messageTemplates'

// Components
import UpdateDeleteButton from '../../../../components/customFormElements/UpdateDeleteButton'
import GeneralCard from '../../../../components/cards/generalCard/GeneralCard'
import LoadingComponent from '../../../../components/shareds/LoadingComponent'
import DetailCard from '../../../../components/cards/detailCard/DetailCard'
import NavCard from '../../../../components/cards/navCard/NavigateCard'

const MessageTemplateDetail = () => {
  const [messageTemplateData, setMessageTemplateData] = useState<any | null>(null)

  const id = location.pathname.split('/message-template/')[1]
  const navigate = useNavigate()

  useEffect(() => {
    messageTemplateServices.findOne(id).then((response) => {
      setMessageTemplateData(response.data)
    })
  }, [])

  const deleteFunction = (id: string) => {
    messageTemplateServices.remove(id).finally(() => {
      navigate('/message-template')
    })
  }

  if (!messageTemplateData) {
    return <LoadingComponent />
  }

  return (
    <DefaultLayout>
      <NavCard icon={MdQuestionAnswer} pageName='Message Template' />

      <GeneralCard title='Message Template Detail'>
        <Grid templateRows='repeat(1, 1fr)' templateColumns='repeat(6, 1fr)' gap={4} margin={'20px'}>
          <DetailCard upperText='type' lowerText={messageTemplateData.type} />
          <DetailCard upperText='name' lowerText={messageTemplateData.name} />
          <DetailCard upperText='languageCode' lowerText={messageTemplateData.languageCode} />
          <GridItem
            colSpan={6}
            children={
              <Card>
                <CardBody>
                  <Heading size='xs' textTransform='uppercase' children={'CONTENT'} />
                  <Box
                    width='100%'
                    height='100%'
                    overflow='auto'
                    dangerouslySetInnerHTML={{
                      __html: messageTemplateData ? messageTemplateData.content : '',
                    }}
                  />
                </CardBody>
              </Card>
            }
          />
        </Grid>
      </GeneralCard>

      <UpdateDeleteButton deleteFunction={deleteFunction} id={id} navigateUrl='/message-template/update' />
    </DefaultLayout>
  )
}

export default MessageTemplateDetail
