import { TableContainer, Table, Tbody, Thead, Td, Th, Tr, Select, Grid, GridItem } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { MdOutlineManageAccounts } from 'react-icons/md'

// Services
import { employeeServices } from '../../services/employees/index'

import DefaultLayout from '../../layouts/DefaultLayout'

// Components
import ActionButton from '../../components/customFormElements/ActionButtons'
import GeneralCard from '../../components/cards/generalCard/GeneralCard'
import LoadingComponent from '../../components/shareds/LoadingComponent'
import NavCard from '../../components/cards/navCard/NavigateCard'
import EmployeeFilter from '../../components/generalFilter/EmployeeFilter'

const EmployeeList = () => {
  const [manageEmployeeData, setManageEmployeeData] = useState<any[]>([])
  const [selectedProject, setSelectedProject] = useState<any | null>(null)
  const [inputValue, setInputValue] = useState('')
  const [country, setCountry] = useState<any | null>(null)
  const [pageNumber, setPageNumber] = useState(Number)
  const [pageLimit, setPageLimit] = useState(Number)
  const navigate = useNavigate()

  const id = location.pathname.split('/user/')[1]

  useEffect(() => {
    const projectId =
      selectedProject && selectedProject.id && selectedProject.id.length > 1
        ? selectedProject.id
        : '8c115a5d-749b-4f0f-9603-b05d2049fa19'

    const searchTag = inputValue.length >= 3 ? inputValue : ''
    const page = pageNumber ? pageNumber : 1
    const limit = pageLimit ? pageLimit : 10
    const countryCode = country ? country.id : ''

    employeeServices.findAll(searchTag, page, limit, countryCode, projectId).then((response) => {
      if (response) {
        setManageEmployeeData(response.data.items)
      } else {
        toast('An error occurred while loading the  data.')
      }
    })
  }, [inputValue, country, pageNumber, pageLimit, selectedProject])

  const updateEmployee = (id: string) => {
    navigate(`/manage-employee/update/${id}`)
  }

  const detailEmployee = (id: string) => {
    navigate(`/manage-employee/${id}`)
  }

  const deleteEmployee = (id: string) => {
    employeeServices.remove(id).finally(() => {})
  }

  if (!manageEmployeeData) {
    return <LoadingComponent />
  }

  return (
    <DefaultLayout>
      <NavCard
        icon={MdOutlineManageAccounts}
        pageName='Employee'
        secondButton='Add Employee'
        secondButtonLink='add'
        key={'Add_Employee'}
      />
      <GeneralCard
        title=''
        children={
          <EmployeeFilter
            setSearchInput={setInputValue}
            setSelectedCountry={setCountry}
            setSelectedProject={setSelectedProject}
          />
        }
      />
      <GeneralCard title='Employee List'>
        <TableContainer maxH='30em' overflowY='auto'>
          <Table variant='simple' size={'sm'}>
            <Thead bg={'white'} position={'sticky'} top={'0px'} zIndex={1}>
              <Tr>
                <Th children={'First Name'} />
                <Th children={'Last Name'} />
                <Th children={'Email'} />
                <Th children={'Address'} />
                <Th children={'Type'} />
              </Tr>
            </Thead>
            {manageEmployeeData.map((item) => {
              return (
                <Tbody paddingBottom={'20px'} key={item.id}>
                  <Tr>
                    <Td
                      onClick={() => detailEmployee(item.id)}
                      cursor={'pointer'}
                      color={'blue'}
                      children={item.firstName}
                    />
                    <Td children={item.lastName} />
                    <Td children={item.email} />
                    <Td children={item.address} />
                    <Td children={item.type} />
                  </Tr>
                </Tbody>
              )
            })}
          </Table>
        </TableContainer>
        <Grid templateRows='repeat(1, 1fr)' templateColumns='repeat(5, 1fr)' gap={2}>
          <GridItem
            colSpan={1}
            children={
              <Select
                w={32}
                value={pageNumber}
                onChange={(e) => {
                  setPageNumber(Number(e.target.value))
                }}
              >
                {[1, 2, 3, 4].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Page {pageSize}
                  </option>
                ))}
              </Select>
            }
          />

          <GridItem
            colSpan={1}
            children={
              <Select
                w={32}
                value={pageLimit}
                onChange={(e) => {
                  setPageLimit(Number(e.target.value))
                }}
              >
                {[10, 20, 50, 100].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </Select>
            }
          />
          <GridItem colSpan={3}></GridItem>
        </Grid>
      </GeneralCard>
    </DefaultLayout>
  )
}

export default EmployeeList
