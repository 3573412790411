import { axiosInstance } from '../axios/index'

export const userServices = {
  create: (requestModel: any) => {
    return axiosInstance.post('users', requestModel)
  },
  update: (id: string, requestModel: any) => {
    return axiosInstance.put(`users/${id}`, requestModel)
  },
  updateStatus: (id: string, requestModel: any) => {
    return axiosInstance.put(`users/updateStatus/${id}`, requestModel)
  },
  findAll: (page: number, limit: number) => {
    return axiosInstance.get(`users?page=${page}&limit=${limit}`)
  },
  findAllByProjectId: (projectId: string, page: number, limit: number) => {
    return axiosInstance.get(`users/findAllByProjectId/${projectId}?page=${page}&limit=${limit}`)
  },
  findOne: (id: string) => {
    return axiosInstance.get(`users/${id}`)
  },
  findOneWithAddress: (id: string) => {
    return axiosInstance.get(`users/findOneWithAddress/${id}`)
  },

  queryGeneralFilter: (requestModel: any) => {
    const constUrl = `users/queryUserById?employeeId=${requestModel.employeeId}&projectId=${requestModel.projectId}&countryCode=${requestModel.countryCode}&sortDirection=${requestModel.sortDirection}&searchTag=${requestModel.searchTag}&callStatus=${requestModel.callStatus}&page=1&limit=10`
    return axiosInstance.get(constUrl)
  },
  queryGeneralSearch: (searchTag: string) => {
    return axiosInstance.get(`users/queryGeneralSearch?searchTag=${searchTag}`)
  },
  remove: (id: string) => {
    return axiosInstance.delete(`users/${id}`)
  },
  updateAssigned: (id: string, requestModel: any) => {
    return axiosInstance.put(`users/updateAssigned/${id}`, requestModel)
  },
  statusList: () => {
    return axiosInstance.get('users/statusList')
  },
}
