import { axiosInstance } from '../../axios'

export const messageTemplateServices = {
  create: (requestModel: any) => {
    return axiosInstance.post('message-templates', requestModel)
  },
  update: (id: string, requestModel: any) => {
    return axiosInstance.put(`message-templates/${id}`, requestModel)
  },
  findAll: (projectId: string) => {
    return axiosInstance.get(`message-templates/findAll/${projectId}`)
  },
  findOne: (id: string) => {
    return axiosInstance.get(`message-templates/${id}`)
  },
  remove: (id: string) => {
    return axiosInstance.delete(`message-templates/${id}`)
  },
}
