import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Grid } from '@chakra-ui/react'

import DefaultLayout from '../../../../layouts/DefaultLayout'

import { MdQuestionAnswer } from 'react-icons/md'

// Services
import { interestingMatcheServices } from '../../../../services/common/interestingMatches'

// Components
import UpdateDeleteButton from '../../../../components/customFormElements/UpdateDeleteButton'
import GeneralCard from '../../../../components/cards/generalCard/GeneralCard'
import LoadingComponent from '../../../../components/shareds/LoadingComponent'
import DetailCard from '../../../../components/cards/detailCard/DetailCard'
import NavCard from '../../../../components/cards/navCard/NavigateCard'

const InterestingMatcheDetail = () => {
  const [interestingMatcheData, setInterestingMatcheData] = useState<any | null>(null)

  const id = location.pathname.split('/interesting-matche/')[1]
  const navigate = useNavigate()

  useEffect(() => {
    interestingMatcheServices.findOne(id).then((response) => {
      setInterestingMatcheData(response.data)
    })
  }, [])

  const deleteFunction = (id: string) => {
    interestingMatcheServices.remove(id).finally(() => {
      navigate('/interesting-matche')
    })
  }

  if (!interestingMatcheData) {
    return <LoadingComponent />
  }

  return (
    <DefaultLayout>
      <NavCard icon={MdQuestionAnswer} pageName='Interesting Matche' />

      <GeneralCard title='Interesting Matche Detail'>
        <Grid templateRows='repeat(3, 1fr)' templateColumns='repeat(4, 1fr)' gap={4} margin={'20px'}>
          <DetailCard upperText='Home Team' lowerText={interestingMatcheData.homeTeam} />
          <DetailCard upperText='Away Team' lowerText={interestingMatcheData.awayTeam} />
          <DetailCard upperText='Home Team Odds' lowerText={interestingMatcheData.homeTeamOdds} />
          <DetailCard upperText='Away Team Odds' lowerText={interestingMatcheData.awayTeamOdds} />
          <DetailCard upperText='Draw Odds' lowerText={interestingMatcheData.drawOdds} />
        </Grid>
      </GeneralCard>

      <UpdateDeleteButton deleteFunction={deleteFunction} id={id} navigateUrl='/interesting-matche/update' />
    </DefaultLayout>
  )
}

export default InterestingMatcheDetail
