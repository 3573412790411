import React, { useEffect } from 'react'
import Select from 'react-select'

import { NoteType } from '../../enums/note-type'

interface ArrayObjectSelectState {
  selectedNoteType: NoteType | null
  setSelectedNoteType: (noteType: NoteType | null) => void
  type?: string
}

const NoteTypeSelect: React.FC<ArrayObjectSelectState> = ({ selectedNoteType, setSelectedNoteType, type }) => {
  const [noteTypes, setNoteTypes] = React.useState<NoteType[]>([])

  useEffect(() => {
    setNoteTypes(Object.values(NoteType))

    if (type) {
      const noteType = noteTypes.find((item) => item === type)
      if (noteType) setSelectedNoteType(noteType)
    }
  }, [type, setNoteTypes])

  return (
    <Select
      value={
        selectedNoteType
          ? {
              value: selectedNoteType,
              label: selectedNoteType?.toUpperCase(),
            }
          : null
      }
      onChange={(option: any) => {
        setSelectedNoteType(option ? option.value : null)
      }}
      getOptionLabel={(option: any) => option.label}
      getOptionValue={(option: any) => option.value}
      options={noteTypes.map((type) => ({
        value: type,
        label: type.toUpperCase(),
      }))}
      isClearable={true}
      backspaceRemovesValue={true}
      placeholder={'TYPE'}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          zIndex: 3,
        }),
      }}
    />
  )
}

export default NoteTypeSelect
