import { useEffect, useState, useRef } from 'react'
import { userServices } from '../../../../services/users/index'
import DefaultLayout from '../../../../layouts/DefaultLayout'
import {
  Avatar,
  Button,
  Grid,
  GridItem,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react'
import { MdAccountBox } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

// Components
import AlertDialogComponent from '../../../../components/dialog/AlertDialogComponent'
import GeneralCard from '../../../../components/cards/generalCard/GeneralCard'
import LoadingComponent from '../../../../components/shareds/LoadingComponent'
import NavCard from '../../../../components/cards/navCard/NavigateCard'
import CustomModal from '../../../../components/modal/CustomModal'
import UserMessageCreate from '../../userMessages/create'
import UserPhoneCreate from '../../userPhones/create'
import UserNoteCreate from '../../userNotes/create'
import UserMessage from '../../userMessages'
import UserPhone from '../../userPhones'
import UserNote from '../../userNotes'

const UserDetail = () => {
  const [userData, setUserData] = useState<any | null>(null)
  const { isOpen: isOpenMessage, onOpen: onOpenMessage, onClose: onCloseMessage } = useDisclosure()
  const { isOpen: isOpenPhone, onOpen: onOpenPhone, onClose: onClosePhone } = useDisclosure()
  const { isOpen: isOpenNote, onOpen: onOpenNote, onClose: onCloseNote } = useDisclosure()

  const id = location.pathname.split('/user/')[1]
  const navigate = useNavigate()

  const [isOpenAlert, setIsOpen] = useState(false)
  const onCloseAlert = () => setIsOpen(false)
  const cancelRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    userServices.findOne(id).then((response) => {
      setUserData(response.data)
    })
  }, [])

  const deleteUser = () => {
    userServices.remove(id).finally(() => {
      onCloseAlert()
      navigate('/manage-employee')
    })
  }

  const deleteFunction = () => {
    setIsOpen(true)
  }

  if (!userData) {
    return <LoadingComponent />
  }

  return (
    <DefaultLayout>
      <NavCard
        icon={MdAccountBox}
        pageName='User'
        firstButton='Update'
        firstButtonLink={`/User/update/${id}`}
        secondButton='Delete'
        secondClickAction={deleteFunction}
      />
      <GeneralCard title='User Profile'>
        <Grid templateRows='repeat(1, 1fr)' templateColumns='repeat(5, 1fr)' gap={4} margin={'20px'}>
          <GridItem
            colSpan={3}
            children={
              <HStack>
                <Avatar size={'md'} />
                <VStack display={{ base: 'none', md: 'flex' }} alignItems='flex-end' spacing='1px' ml='2'>
                  <Text fontSize='2xl' fontWeight='bold' children={userData.firstName + ' ' + userData.lastName} />
                </VStack>
                <VStack display={{ base: 'none', md: 'flex' }} alignItems='flex-end' spacing='1px' ml='2'>
                  <Text fontWeight='bold' color={'blue'} children={userData.email} />
                </VStack>
              </HStack>
            }
          />
          <GridItem colSpan={2} />
        </Grid>
      </GeneralCard>

      <GeneralCard title='Detail'>
        <>
          <AlertDialogComponent
            isOpen={isOpenAlert}
            onClose={onCloseAlert}
            cancelRef={cancelRef}
            onConfirm={deleteUser}
          />
        </>
        <Tabs size='lg' variant='enclosed'>
          <TabList>
            <Tab children='Message' />
            <Tab children='Phones' />
            <Tab children='Notes' />
          </TabList>
          <TabPanels>
            <TabPanel>
              <UserMessage />
              <Button colorScheme='red' onClick={onOpenMessage} mt={'20px'} children={'Send'} />
              <CustomModal
                title='User Message Create'
                isOpen={isOpenMessage}
                onClose={onCloseMessage}
                key={'User_Message_Create'}
                children={<UserMessageCreate />}
              />
            </TabPanel>

            <TabPanel>
              <UserPhone />
              <Button colorScheme='red' onClick={onOpenPhone} mt={'20px'} children={'Create'} />
              <CustomModal
                title='User Phone Create'
                isOpen={isOpenPhone}
                onClose={onClosePhone}
                key={'User_Phone_Create'}
                children={<UserPhoneCreate />}
              />
            </TabPanel>

            <TabPanel>
              <UserNote />
              <Button colorScheme='red' onClick={onOpenNote} mt={'20px'} children={'Create'} />
              <CustomModal
                title='User Note Create'
                isOpen={isOpenNote}
                onClose={onCloseNote}
                key={'User_Note_Create'}
                children={<UserNoteCreate />}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </GeneralCard>
    </DefaultLayout>
  )
}

export default UserDetail
