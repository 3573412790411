import { useEffect, useState } from 'react'
import { Grid } from '@chakra-ui/react'

// Services
import { userMessageServices } from '../../../../services/users/userMessage/index'

// Components
import CustomInputForm from '../../../../components/customFormElements/CustomInputForm'
import LoadingComponent from '../../../../components/shareds/LoadingComponent'
import DetailCard from '../../../../components/cards/detailCard/DetailCard'

const UserMessageDetail = (props: { id: string }) => {
  const [userMessageData, setUserMessageData] = useState<any | null>(null)

  useEffect(() => {
    userMessageServices.findOne(props.id).then((response) => {
      setUserMessageData(response.data)
    })
  }, [])

  if (!userMessageData) {
    return <LoadingComponent />
  }

  return (
    <>
      <Grid templateRows='repeat(2, 1fr)' templateColumns='repeat(4, 1fr)' gap={2} margin={'20px'}>
        <CustomInputForm children={<DetailCard upperText='employee Name' lowerText={userMessageData.employeeName} />} />
        <CustomInputForm children={<DetailCard upperText='message Type' lowerText={userMessageData.messageType} />} />
        <CustomInputForm children={<DetailCard upperText='status' lowerText={userMessageData.status} />} />
        <CustomInputForm
          children={<DetailCard upperText='message Content' lowerText={userMessageData.messageContent} />}
        />
      </Grid>
    </>
  )
}
export default UserMessageDetail
