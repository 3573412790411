import { Grid, GridItem } from '@chakra-ui/react'
import { useEffect, useState } from 'react'

// Components
import ProjectSelect from '../selects/ProjectSelect'

interface ArrayObjectSelectState {
  setSearchInput: (searchTag: any) => void
  setSelectedProject: (project: any) => void
}

const ProjectFilter: React.FC<ArrayObjectSelectState> = ({ setSearchInput, setSelectedProject }) => {
  const [inputValue, setInputValue] = useState('')
  const [project, setProject] = useState<any | null>(null)

  useEffect(() => {
    setSearchInput(inputValue)
    setSelectedProject(project)
  }, [opener, inputValue, project])

  return (
    <Grid templateRows='repeat(1, 1fr)' templateColumns='repeat(5, 1fr)' gap={2}>
      <GridItem colSpan={3} />
      <GridItem colSpan={2} children={<ProjectSelect selectedProject={project} setSelectedProject={setProject} />} />
    </Grid>
  )
}

export default ProjectFilter
