import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { MdAccountBox } from 'react-icons/md'
import { useEffect, useState } from 'react'

import DefaultLayout from '../../../layouts/DefaultLayout'

// Services
import { userServices } from '../../../services/users'

// Components
import GeneralFilter from '../../../components/generalFilter/AlternativeGeneralFilter'
import GeneralCard from '../../../components/cards/generalCard/GeneralCard'
import NavCard from '../../../components/cards/navCard/NavigateCard'
import UserTable from '../../../components/tables/userTable/UserTable'

const User = () => {
  const [userData, setUsersData] = useState<any[]>([])
  const [inputValue, setInputValue] = useState('')
  const [country, setCountry] = useState<any | null>(null)
  const [project, setProject] = useState<any | null>(null)
  const [sortByValue, setSortBy] = useState<any | null>(null)
  const [pageNumber, setPageNubmer] = useState('')
  const [pageLimit, setPageLimit] = useState('')

  useEffect(() => {
    const requestModel = {
      sortDirection: sortByValue ? sortByValue.value : '',
      searchTag: inputValue.length >= 3 ? inputValue : '',
      page: pageNumber ? pageNumber : 1,
      limit: pageLimit ? pageLimit : 10,
      countryCode: country ? country.alpha2Code : '',
      projectId: project ? project.id : '',
      employeeId: '',
      callStatus: '',
    }

    userServices.queryGeneralFilter(requestModel).then((response) => {
      console.log(response.data.items)
      if (response) setUsersData(response.data.items)
    })
  }, [inputValue, country, sortByValue, pageNumber, pageLimit, project])

  return (
    <DefaultLayout>
      <NavCard
        icon={MdAccountBox}
        pageName='User'
        firstButton='Invite'
        firstButtonLink='invite-user'
        secondButton='Add USer'
        secondButtonLink='add'
        key={'Nav_Card'}
      />
      <GeneralCard title=''>
        <GeneralFilter
          setSearchInput={setInputValue}
          setSelectedCountry={setCountry}
          setSelectedSortBy={setSortBy}
          setSelectedProject={setProject}
        />
      </GeneralCard>

      <GeneralCard title='User List'>
        <UserTable setSelectedPageLimit={setPageLimit} setSelectedPageNumber={setPageNubmer} userData={userData} />
        <ToastContainer />
      </GeneralCard>
    </DefaultLayout>
  )
}

export default User
