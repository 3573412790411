import { Button, Flex, Grid, GridItem } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useState } from 'react'

// Services
import { employeeCallServices } from '../../../services/employees/employeeCall'

// Components
import CustomInputForm from '../../../components/customFormElements/CustomInputForm'
import DatePickerComponent from '../../../components/customFormElements/DatePicker'
import GeneralCard from '../../../components/cards/generalCard/GeneralCard'
import ProjectSelect from '../../../components/selects/ProjectSelect'

type EmployeeCallFormData = {
  startDate: Date
  endDate: Date
}

const EmployeeCallCreate = () => {
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null)
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null)
  const [selectedProject, setSelectedProject] = useState<any | null>(null)
  const handleOnSubmit = () => {
    handleSubmit(onSubmit)()
  }

  const {
    handleSubmit,
    formState: { errors },
  } = useForm<EmployeeCallFormData>()

  const onSubmit = (data: EmployeeCallFormData) => {
    if (!selectedStartDate) {
      toast('Start Date is required')
      return
    }
    if (!selectedEndDate) {
      toast('End Date is required')
      return
    }

    data.startDate = selectedStartDate
    data.endDate = selectedEndDate

    employeeCallServices.create(data).then((response) => {
      if (response.status === 201) toast(response.data)
    })
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <GeneralCard title='Create Call List'>
          <Grid templateRows='repeat(2, 1fr)' templateColumns='repeat(4, 1fr)' gap={4} margin={'20px'}>
            <CustomInputForm
              title='Project'
              customColSpan={4}
              children={<ProjectSelect selectedProject={selectedProject} setSelectedProject={setSelectedProject} />}
            />
            <CustomInputForm
              title='Start Date'
              children={<DatePickerComponent selectedDate={selectedStartDate} setSelectedDate={setSelectedStartDate} />}
            />
            <CustomInputForm
              title='End Date'
              children={<DatePickerComponent selectedDate={selectedEndDate} setSelectedDate={setSelectedEndDate} />}
            />
          </Grid>
        </GeneralCard>
      </form>
      <Flex margin={'0px 0px 0px 20px'}>
        <Button colorScheme='red' size={'md'} minWidth={'120px'} onClick={() => handleOnSubmit()} children={'Add'} />
      </Flex>
    </>
  )
}

export default EmployeeCallCreate
