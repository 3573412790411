import React, { useEffect } from 'react'
import Select from 'react-select'

interface OptionData {
  key: string
  value: string
}

interface ArrayObjectSelectState {
  selectedSortBy: OptionData | null
  setSelectedSortBy: (sortByValue: OptionData | null) => void
  value?: string
}

const SortBySelect: React.FC<ArrayObjectSelectState> = ({ selectedSortBy, setSelectedSortBy, value }) => {
  const [sortBy, setSortBy] = React.useState<OptionData[]>([])

  useEffect(() => {
    const loadCountries = async () => {
      const companyData: OptionData[] = [
        { key: 'Account Date ↓', value: 'ASC' },
        { key: 'Account Date ↑', value: 'DESC' },
      ]
      setSortBy(
        companyData.map<OptionData>((item) => {
          return {
            key: item.key,
            value: item.value,
          }
        }),
      )
      if (value) {
        const sortByValue = companyData.find((item) => item.value === value)
        if (sortByValue) setSelectedSortBy(sortByValue)
      }
    }
    loadCountries()
  }, [value, setSelectedSortBy])

  return (
    <Select
      value={selectedSortBy}
      onChange={(option: OptionData | null) => {
        setSelectedSortBy(option)
      }}
      getOptionLabel={(option: OptionData) => option.key}
      getOptionValue={(option: OptionData) => option.value}
      options={sortBy}
      isClearable={true}
      backspaceRemovesValue={true}
      placeholder={'SORT BY'}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          zIndex: 3,
        }),
      }}
    />
  )
}

export default SortBySelect
