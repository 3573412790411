import { Box, Button, Flex, Grid, Input } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'
import { MdAccountBox } from 'react-icons/md'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as Yup from 'yup'

import DefaultLayout from '../../../../layouts/DefaultLayout'

// Services
import { userServices } from '../../../../services/users/index'

// Components
import CustomInputForm from '../../../../components/customFormElements/CustomInputForm'
import ContractStatusSelect from '../../../../components/selects/ContractStatusSelect'
import CustomCheckbox from '../../../../components/customFormElements/CustomCheckbox'
import GeneralCard from '../../../../components/cards/generalCard/GeneralCard'
import LoadingComponent from '../../../../components/shareds/LoadingComponent'
import UserTypeSelect from '../../../../components/selects/UserTypeSelect'
import LanguageSelect from '../../../../components/selects/LanguageSelect'
import NavCard from '../../../../components/cards/navCard/NavigateCard'
import CountrySelect from '../../../../components/selects/CountrySelect'

type UserFormData = {
  firstName: string
  lastName: string
  email: string
  mainPhoneNumber: string
  languageCode: string
  mainSocialMediaName: string
  mainSocialMediaType: string
  followerNumber: number
  isSpecial: false
  userType: string
  country: string
  countryCode: string
  contractStatus: string
  postCode: string
  city: string
  state: string
  detail: string
  isMain: boolean
  openerId: string
  ownerId: string
  closerId: string
}

const UserUpdate = () => {
  const [selectedContractStatus, setSelectedContractStatus] = useState<any | null>(null)
  const [selectedSocialMedia, setSelectedSocialMedia] = useState<any | null>(null)
  const [selectedLanguage, setSelectedLanguage] = useState<any | null>(null)
  const [selectedUserType, setSelectedUserType] = useState<any | null>(null)
  const [selectedCountry, setSelectedCountry] = useState<any | null>(null)

  const [selectedOpener, setSelectedOpenerData] = useState<any | null>(null)
  const [selectedCloser, setSelectedCloserData] = useState<any | null>(null)
  const [selectedOwner, setSelectedOwnerData] = useState<any | null>(null)

  const [specialChecked, setSpecialChecked] = useState(false)
  const [mainChecked, setMainChecked] = useState(false)
  const [userData, setUserData] = useState<any | null>(null)
  const navigate = useNavigate()

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    mainPhoneNumber: Yup.string().required('Phone Number is required'),
    mainSocialMediaName: Yup.string().required('Social Media Name is required'),
    followerNumber: Yup.number().required('Follower Number is required'),
    isSpecial: Yup.boolean().required('Special is required'),
    postCode: Yup.string().required('Post Code is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    detail: Yup.string().required('Detail is required'),
    isMain: Yup.boolean().required('Main is required'),
  })

  const id = location.pathname.split('/user/update/')[1]

  useEffect(() => {
    userServices.findOneWithAddress(id).then((response) => {
      if (response) {
        setUserData(response.data)
        setSpecialChecked(response.data.isSpecial)
        setMainChecked(response.data.isMain)
      }
    })
  }, [])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserData({
      ...userData,
      [event.target.name]: event.target.value,
    })
  }

  const handleMainCheckedChange = (newCheckState: boolean) => {
    setMainChecked(newCheckState)
  }
  const handleSpecialCheckedChange = (newCheckState: boolean) => {
    setSpecialChecked(newCheckState)
  }

  const handleOnSubmit = () => {
    handleSubmit(onSubmit)()
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<UserFormData>({
    resolver: yupResolver(validationSchema),
  })

  type ValueType = string | number | undefined

  const selectedOrDefault = (dataValue: ValueType, selectedValue: ValueType, defaultValue?: ValueType) =>
    dataValue || selectedValue || defaultValue

  type SelectedType = { id: ValueType } | null

  const assignselectedOrDefault = (dataValue: ValueType, selectedValue: SelectedType, defaultValue: ValueType) => {
    if (!dataValue) return selectedValue === null ? defaultValue : selectedValue.id
    return dataValue
  }

  const onSubmit = (data: UserFormData) => {
    const preparedData = {
      ...data,
      mainSocialMediaType: selectedOrDefault(data.mainSocialMediaType, selectedSocialMedia.name),
      languageCode: selectedOrDefault(data.languageCode, selectedLanguage.code),
      userType: selectedOrDefault(data.userType, selectedUserType),
      countryCode: selectedOrDefault(data.countryCode, selectedCountry.alpha2Code),
      country: selectedOrDefault(data.country, selectedCountry.name),
      contractStatus: selectedOrDefault(data.contractStatus, selectedContractStatus),
      openerId: assignselectedOrDefault(data.openerId, selectedOpener, userData.openerId),
      ownerId: assignselectedOrDefault(data.ownerId, selectedOwner, userData.ownerId),
      closerId: assignselectedOrDefault(data.closerId, selectedCloser, userData.closerId),
    }

    userServices
      .update(id, preparedData)
      .then((response) => {
        if (response) navigate('/user')
      })
      .catch((error) => {
        toast(error.response.data.message[0])
      })
  }

  if (!userData) {
    return <LoadingComponent />
  }

  return (
    <DefaultLayout>
      <NavCard icon={MdAccountBox} pageName='User' />
      <form onSubmit={handleSubmit(onSubmit)}>
        <GeneralCard title='Update User'>
          <Grid templateRows='repeat(3, 1fr)' templateColumns='repeat(4, 1fr)' gap={4} margin={'20px'}>
            <CustomInputForm
              error={errors.firstName}
              title='First Name'
              children={
                <Input
                  type='text'
                  id='firstName'
                  {...register('firstName')}
                  value={userData.firstName}
                  onChange={handleInputChange}
                />
              }
            />

            <CustomInputForm
              error={errors.lastName}
              title='Last Name'
              children={
                <Input
                  type='text'
                  id='lastName'
                  {...register('lastName')}
                  value={userData.lastName}
                  onChange={handleInputChange}
                />
              }
            />

            <CustomInputForm
              error={errors.email}
              title='Email'
              children={
                <Input
                  type='text'
                  id='email'
                  {...register('email')}
                  value={userData.email}
                  onChange={handleInputChange}
                />
              }
            />
            <CustomInputForm
              error={errors.mainPhoneNumber}
              title='Phone Number'
              children={
                <Input
                  type='text'
                  id='mainPhoneNumber'
                  {...register('mainPhoneNumber')}
                  value={userData.mainPhoneNumber}
                  onChange={handleInputChange}
                />
              }
            />
            <CustomInputForm
              title='User Type'
              children={
                <UserTypeSelect
                  selectedUserType={selectedUserType}
                  setSelectedUserType={setSelectedUserType}
                  type={userData.userType}
                />
              }
            />
            <CustomInputForm
              title='Language'
              children={
                <LanguageSelect
                  selectedLanguage={selectedLanguage}
                  setSelectedLanguage={setSelectedLanguage}
                  code={userData.languageCode}
                />
              }
            />
            <CustomInputForm
              title='Status'
              children={
                <ContractStatusSelect
                  selectedContractStatus={selectedContractStatus}
                  setSelectedContractStatus={setSelectedContractStatus}
                  statusType={userData.contractStatus}
                />
              }
            />
            <Box mt={5}>
              <CustomInputForm
                children={
                  <CustomCheckbox
                    defaultChecked={specialChecked}
                    onCheckChange={handleSpecialCheckedChange}
                    name={'Special'}
                    register={register}
                    registerName='isSpecial'
                  />
                }
              />
            </Box>
          </Grid>
        </GeneralCard>

        <Flex>
          <GeneralCard title='Update Main Address' customWidth='45%'>
            <Grid templateRows='repeat(3, 1fr)' templateColumns='repeat(4, 1fr)' gap={4} margin={'20px'}>
              <CustomInputForm
                title='Country'
                children={
                  <CountrySelect
                    selectedCountry={selectedCountry}
                    setSelectedCountry={setSelectedCountry}
                    selectCountry={userData.countryCode}
                  />
                }
              />
              <CustomInputForm
                error={errors.postCode}
                title='Post Code'
                children={
                  <Input
                    type='text'
                    id='postCode'
                    {...register('postCode')}
                    value={userData.postCode}
                    onChange={handleInputChange}
                  />
                }
              />
              <CustomInputForm
                error={errors.city}
                title='City'
                children={
                  <Input
                    type='text'
                    id='city'
                    {...register('city')}
                    value={userData.city}
                    onChange={handleInputChange}
                  />
                }
              />
              <CustomInputForm
                error={errors.state}
                title='State'
                children={
                  <Input
                    type='text'
                    id='state'
                    {...register('state')}
                    placeholder=''
                    value={userData.state}
                    onChange={handleInputChange}
                  />
                }
              />
              <CustomInputForm
                error={errors.detail}
                title='Detail'
                children={
                  <Input
                    type='text'
                    id='detail'
                    {...register('detail')}
                    placeholder='Detail'
                    value={userData.detail}
                    onChange={handleInputChange}
                  />
                }
              />
              <Box mt={5}>
                <CustomInputForm
                  children={
                    <CustomCheckbox
                      defaultChecked={mainChecked}
                      onCheckChange={handleMainCheckedChange}
                      name={'Main'}
                      register={register}
                      registerName='isMain'
                    />
                  }
                />
              </Box>
            </Grid>
          </GeneralCard>
          <GeneralCard title='Update Primary Account' customWidth='45%'>
            <Grid templateRows='repeat(3, 1fr)' templateColumns='repeat(2, 1fr)' gap={4} margin={'20px'}>
              <CustomInputForm title='Social Media' />
              <CustomInputForm
                error={errors.mainSocialMediaName}
                title='User Name'
                children={
                  <Input
                    bg={'white'}
                    type='text'
                    id='mainSocialMediaName'
                    {...register('mainSocialMediaName')}
                    placeholder='Social Media Name'
                    value={userData.mainSocialMediaName}
                    onChange={handleInputChange}
                  />
                }
              />
              <CustomInputForm
                error={errors.followerNumber}
                title='Follower Number'
                children={
                  <Input
                    bg={'white'}
                    type='number'
                    id='followerNumber'
                    {...register('followerNumber')}
                    placeholder='Follower Number'
                    value={userData.followerNumber}
                    onChange={handleInputChange}
                  />
                }
              />
            </Grid>
          </GeneralCard>
        </Flex>
      </form>
      <Flex margin={'20px 0px 40px 40px'}>
        <Button colorScheme='red' size={'lg'} minWidth={'210px'} onClick={() => handleOnSubmit()} children={'Update'} />
      </Flex>
    </DefaultLayout>
  )
}
export default UserUpdate
