import { Grid, GridItem, Input } from '@chakra-ui/react'
import { useEffect, useState } from 'react'

// Components
import CountrySelect from '../selects/CountrySelect'
import SortBySelect from '../selects/SortBySelect'
import ProjectSelect from '../selects/ProjectSelect'

interface ArrayObjectSelectState {
  setSearchInput: (searchTag: any) => void
  setSelectedCountry: (country: any) => void
  setSelectedSortBy: (sortByValue: any) => void
  setSelectedProject: (project: any) => void
}

const GeneralFilter: React.FC<ArrayObjectSelectState> = ({
  setSearchInput,

  setSelectedCountry,
  setSelectedSortBy,
  setSelectedProject,
}) => {
  const [inputValue, setInputValue] = useState('')
  const [country, setCountry] = useState<any | null>(null)
  const [sortByValue, setSortBy] = useState<any | null>(null)
  const [project, setProject] = useState<any | null>(null)

  useEffect(() => {
    setSearchInput(inputValue)
    setSelectedCountry(country)
    setSelectedSortBy(sortByValue)
    setSelectedProject(project)
  }, [opener, inputValue, country, sortByValue, project])

  return (
    <Grid templateRows='repeat(1, 1fr)' templateColumns='repeat(6, 1fr)' gap={2}>
      <GridItem
        colSpan={3}
        children={
          <Input bg={'white'} type='text' placeholder='Search...' onChange={(e) => setInputValue(e.target.value)} />
        }
      />

      <GridItem
        colSpan={1}
        children={<CountrySelect selectCountry={''} setSelectedCountry={setCountry} selectedCountry={country} />}
      />
      <GridItem
        colSpan={1}
        children={<SortBySelect selectedSortBy={sortByValue} setSelectedSortBy={setSortBy} value='DESC' />}
      />
      <GridItem colSpan={1} children={<ProjectSelect selectedProject={project} setSelectedProject={setProject} />} />
    </Grid>
  )
}

export default GeneralFilter
