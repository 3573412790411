import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import DefaultLayout from '../../../layouts/DefaultLayout'

// Services
import { interestingMatcheServices } from '../../../services/common/interestingMatches/index'

// Components
import ActionButton from '../../../components/customFormElements/ActionButtons'
import GeneralCard from '../../../components/cards/generalCard/GeneralCard'
import NavCard from '../../../components/cards/navCard/NavigateCard'
import { IoFootballSharp } from 'react-icons/io5'
import ProjectFilter from '../../../components/generalFilter/ProjectFilter'

const InterestingMatche = () => {
  const [interestingMatchesData, setInterestingMatchesData] = useState<any[]>([])
  const [selectedProject, setSelectedProject] = useState<any | null>(null)
  const [inputValue, setInputValue] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    const projectId =
      selectedProject && selectedProject.id && selectedProject.id.length > 1
        ? selectedProject.id
        : '8c115a5d-749b-4f0f-9603-b05d2049fa19'

    interestingMatcheServices.findAll(projectId).then((response) => {
      if (response) setInterestingMatchesData(response.data)
      else toast('An error occurred while loading the Message Template data.')
    })
  }, [selectedProject])

  const updateInterestingMatche = (id: string) => {
    navigate(`/interesting-matche/update/${id}`)
  }

  const detailInterestingMatche = (id: string) => {
    navigate(`/interesting-matche/${id}`)
  }

  const deleteInterestingMatche = (id: string) => {
    interestingMatcheServices.remove(id).finally(() => {
      window.location.reload()
    })
  }

  return (
    <DefaultLayout>
      <NavCard
        icon={IoFootballSharp}
        pageName='Interesting Matche'
        secondButton='Add Interesting Matche'
        secondButtonLink='add'
      />
      <GeneralCard
        title=''
        children={<ProjectFilter setSearchInput={setInputValue} setSelectedProject={setSelectedProject} />}
      />
      <GeneralCard title='Interesting Matche List' customColumn={{ base: 1, md: 1, lg: 1, '2xl': 1 }}>
        <TableContainer>
          <Table variant='simple' size={'sm'}>
            <Thead bg={'white'} position={'sticky'} top={'0px'} zIndex={1}>
              <Tr>
                <Th children={'homeTeam'} />
                <Th children={'awayTeam'} />
                <Th children={'homeTeamOdds'} />
                <Th children={'awayTeamOdds'} />
                <Th children={'drawOdds'} />
                <Th children={'ACTION'} />
              </Tr>
            </Thead>
            {interestingMatchesData.map((item) => {
              return (
                <Tbody paddingBottom={'20px'} key={item.id}>
                  <Tr>
                    <Td onClick={() => detailInterestingMatche(item.id)} cursor={'pointer'} children={item.homeTeam} />
                    <Td children={item.awayTeam} />
                    <Td children={item.homeTeamOdds} />
                    <Td children={item.awayTeamOdds} />
                    <Td children={item.drawOdds} />
                    <Td
                      children={
                        <ActionButton
                          deleteFunction={deleteInterestingMatche}
                          id={item.id}
                          updateFunction={updateInterestingMatche}
                        />
                      }
                    />
                  </Tr>
                </Tbody>
              )
            })}
          </Table>
        </TableContainer>
      </GeneralCard>
    </DefaultLayout>
  )
}

export default InterestingMatche
