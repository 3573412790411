import React, { useEffect } from 'react'
import Select from 'react-select'

import { MessageType } from '../../enums/message-type'

interface ArrayObjectSelectState {
  selectedMessageType: MessageType | null
  setSelectedMessageType: (messageType: MessageType | null) => void
  type?: string
}

const MessageTypeSelect: React.FC<ArrayObjectSelectState> = ({ selectedMessageType, setSelectedMessageType, type }) => {
  const [messageTypes, setMessageTypes] = React.useState<MessageType[]>([])

  useEffect(() => {
    setMessageTypes(Object.values(MessageType))

    if (type) {
      const messageType = messageTypes.find((item) => item === type)
      if (messageType) setSelectedMessageType(messageType)
    }
  }, [type, setMessageTypes])

  return (
    <Select
      value={
        selectedMessageType
          ? {
              value: selectedMessageType,
              label: selectedMessageType?.toUpperCase(),
            }
          : null
      }
      onChange={(option: any) => {
        setSelectedMessageType(option ? option.value : null)
      }}
      getOptionLabel={(option: any) => option.label}
      getOptionValue={(option: any) => option.value}
      options={messageTypes.map((type) => ({
        value: type,
        label: type.toUpperCase(),
      }))}
      isClearable={true}
      backspaceRemovesValue={true}
      placeholder={'TYPE'}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          zIndex: 3,
        }),
      }}
    />
  )
}

export default MessageTypeSelect
