import { Button, Input, Flex, Grid, Box, Textarea } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useState } from 'react'

// Services
import { userMessageServices } from '../../../../services/users/userMessage/index'

// Components
import CustomInputForm from '../../../../components/customFormElements/CustomInputForm'

import { toast } from 'react-toastify'
import MessageSelect from '../../../../components/selects/MessageSelect'
import DetailCard from '../../../../components/cards/detailCard/DetailCard'

type UserMessageFormData = {
  userId: string
  messageId: string
  messageContent: string
  messageType: string
}

const UserMessageCreate = () => {
  const [selectedMessage, setSelectedMessage] = useState<any | null>(null)
  const id = location.pathname.split('/user/')[1]

  const handleOnSubmit = () => {
    handleSubmit(onSubmit)()
  }

  const {
    handleSubmit,
    formState: { errors },
  } = useForm<UserMessageFormData>({})

  const onSubmit = (data: UserMessageFormData) => {
    if (!selectedMessage) {
      toast('Message Type is required')
      return
    }
    data.userId = id
    data.messageId = selectedMessage.id
    data.messageType = selectedMessage.type
    data.messageContent = selectedMessage.content

    userMessageServices.create(data).finally(() => {
      window.location.reload()
    })
  }

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid templateRows='repeat(1, 1fr)' templateColumns='repeat(4, 1fr)' gap={4} margin={'20px'}>
          <CustomInputForm
            customColSpan={4}
            title='Message'
            children={<MessageSelect selectedMessage={selectedMessage} setSelectedMessage={setSelectedMessage} />}
          />

          <CustomInputForm
            customColSpan={4}
            title='Message Content'
            children={<DetailCard upperText='' lowerText={selectedMessage ? selectedMessage.content : ''} />}
          />
        </Grid>
      </form>
      <Flex width={'full'} ml={'20px'}>
        <Button colorScheme='red' size={'sm'} onClick={() => handleOnSubmit()} children={'Send'} />
      </Flex>
    </Box>
  )
}
export default UserMessageCreate
