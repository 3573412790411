import { ContractStatus } from '../../enums/contract-status'
import React, { useEffect } from 'react'
import Select from 'react-select'
interface ArrayObjectSelectState {
  selectedContractStatus: ContractStatus | null
  setSelectedContractStatus: (contractStatu: ContractStatus | null) => void
  statusType?: string
}

const ContractStatusSelect: React.FC<ArrayObjectSelectState> = ({
  selectedContractStatus,
  setSelectedContractStatus,
  statusType,
}) => {
  const [contractStatus, setContractStatus] = React.useState<ContractStatus[]>([])

  useEffect(() => {
    setContractStatus(Object.values(ContractStatus))
  }, [])

  useEffect(() => {
    if (statusType) {
      const contractStatu = contractStatus.find((item) => item === statusType)
      if (contractStatu) setSelectedContractStatus(contractStatu)
    }
  }, [statusType, contractStatus])

  return (
    <Select
      value={
        selectedContractStatus
          ? {
              value: selectedContractStatus,
              label: selectedContractStatus?.toUpperCase(),
            }
          : null
      }
      onChange={(option: any) => {
        setSelectedContractStatus(option ? option.value : null)
      }}
      getOptionLabel={(option: any) => option.label}
      getOptionValue={(option: any) => option.value}
      options={contractStatus.map((type) => ({
        value: type,
        label: type.toUpperCase(),
      }))}
      isClearable={true}
      backspaceRemovesValue={true}
      placeholder={'CONTRACT STATUS'}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          zIndex: 3,
        }),
      }}
    />
  )
}

export default ContractStatusSelect
