import { axiosInstance } from '../../axios/index'

export const employeeCallServices = {
  create: (requestModel: any) => {
    return axiosInstance.post(`employee/employee-call/`, requestModel)
  },
  query: (page: number, limit: number, employeeId: string, callStatus: string, projectId: string) => {
    return axiosInstance.get(
      `employee/employee-call?page=${page}&limit=${limit}&employeeId=${employeeId}&callStatus=${callStatus}&projectId=${projectId}`,
    )
  },
}
