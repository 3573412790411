import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Box, position } from '@chakra-ui/react'
import Select from 'react-select'

import { countryServices } from '../../services/common/countries/index'

interface CountryData {
  id: string
  name: string
  flag: string
  alpha2Code: string
  callingCodes: string
}

interface ArrayObjectSelectState {
  selectedCountry: CountryData | null
  setSelectedCountry: (country: CountryData | null) => void
  selectCountry: string
}

const formatOptionLabel = (data: CountryData) => (
  <Box display='flex' alignItems='center'>
    <img src={data.flag} alt={data.name} style={{ width: '20px', marginRight: '8px' }} />
    {data.name}
  </Box>
)

const CountrySelect: React.FC<ArrayObjectSelectState> = ({ selectedCountry, setSelectedCountry, selectCountry }) => {
  const [countries, setCountry] = useState<CountryData[]>([])

  useEffect(() => {
    const loadCountries = async () => {
      const response = await countryServices.findAll()
      if (response) {
        const countryData = response.data as CountryData[]
        setCountry(
          countryData.map<CountryData>((item) => {
            return {
              id: item.id,
              name: item.name,
              flag: item.flag,
              alpha2Code: item.alpha2Code,
              callingCodes: item.callingCodes,
            }
          }),
        )
        if (selectCountry) {
          if (selectCountry.length > 3) {
            const country = countryData.find((item) => item.id === selectCountry)
            if (country) setSelectedCountry(country)
          } else {
            const country = countryData.find((item) => item.alpha2Code === selectCountry)
            if (country) setSelectedCountry(country)
          }
        }
      } else {
        toast('An error occurred while loading the Country data.')
      }
    }
    loadCountries()
  }, [selectCountry, setSelectedCountry])

  return (
    <Select
      value={selectedCountry}
      onChange={(option: CountryData | null) => {
        setSelectedCountry(option)
      }}
      formatOptionLabel={formatOptionLabel}
      getOptionLabel={(option: CountryData) => option.name}
      getOptionValue={(option: CountryData) => option.alpha2Code}
      options={countries}
      isClearable={true}
      backspaceRemovesValue={true}
      placeholder={'COUNTRY'}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          zIndex: 3,
        }),
      }}
    />
  )
}

export default CountrySelect
