import { TableContainer, Table, Tbody, Thead, Td, Th, Tr, useDisclosure } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { MdOutlineManageAccounts } from 'react-icons/md'

// Services
import { employeeCallServices } from '../../services/employees/employeeCall/index'

import DefaultLayout from '../../layouts/DefaultLayout'

// Components
import GeneralCard from '../../components/cards/generalCard/GeneralCard'
import LoadingComponent from '../../components/shareds/LoadingComponent'
import NavCard from '../../components/cards/navCard/NavigateCard'
import CustomModal from '../../components/modal/CustomModal'
import EmployeeCallCreate from './create'
import ProjectFilter from '../../components/generalFilter/ProjectFilter'

const EmployeeCallList = () => {
  const {
    isOpen: isOpenEmployeeCallCreate,
    onOpen: onOpenEmployeeCallCreate,
    onClose: onCloseEmployeeCallCreate,
  } = useDisclosure()
  const [employeeCallData, setEmployeeCallData] = useState<any[]>([])
  const [selectedProject, setSelectedProject] = useState<any | null>(null)
  const [inputValue, setInputValue] = useState('')

  const navigate = useNavigate()

  const id = location.pathname.split('/user/')[1]

  useEffect(() => {
    const projectId =
      selectedProject && selectedProject.id && selectedProject.id.length > 1
        ? selectedProject.id
        : '8c115a5d-749b-4f0f-9603-b05d2049fa19'

    employeeCallServices.query(1, 100, '', '', projectId).then((response) => {
      if (response) {
        setEmployeeCallData(response.data.items)
      } else {
        toast('An error occurred while loading the  data.')
      }
    })
  }, [selectedProject])

  const detailEmployee = (id: string) => {
    navigate(`/manage-employee/${id}`)
  }

  if (!employeeCallData) {
    return <LoadingComponent />
  }

  return (
    <DefaultLayout>
      <NavCard
        icon={MdOutlineManageAccounts}
        pageName='Employee Call List'
        secondButton='Create'
        secondClickAction={onOpenEmployeeCallCreate}
        key={'Add_Employee'}
      />
      <GeneralCard
        title=''
        children={<ProjectFilter setSearchInput={setInputValue} setSelectedProject={setSelectedProject} />}
      />
      <GeneralCard title='Employee Call List'>
        <TableContainer maxH='30em' overflowY='auto'>
          <Table variant='simple' size={'sm'}>
            <Thead bg={'white'} position={'sticky'} top={'0px'} zIndex={1}>
              <Tr>
                <Th children={'Agent Name'} />
                <Th children={'Customer Name'} />
                <Th children={'Email'} />
                <Th children={'Call Status'} />
                <Th children={'Updated Date'} />
              </Tr>
            </Thead>
            {employeeCallData.map((item) => {
              return (
                <Tbody paddingBottom={'20px'} key={item.id}>
                  <Tr>
                    <Td
                      onClick={() => detailEmployee(item.id)}
                      cursor={'pointer'}
                      color={'blue'}
                      children={item.employeeName}
                    />
                    <Td children={item.name} />
                    <Td children={item.email} />
                    <Td children={item.callStatus} />
                    <Td children={item.updatedDate} />
                  </Tr>
                </Tbody>
              )
            })}
          </Table>
        </TableContainer>

        <CustomModal
          title='Employee Call Create'
          isOpen={isOpenEmployeeCallCreate}
          onClose={onCloseEmployeeCallCreate}
          key={'Employee_Call_Create'}
          size={'xl'}
          children={<EmployeeCallCreate />}
        />
      </GeneralCard>
    </DefaultLayout>
  )
}

export default EmployeeCallList
