import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Select from 'react-select'

import { messageTemplateServices } from '../../services/common/messageTemplates/index'

interface MessageData {
  id: string
  type: string
  name: string
  content: string
  languageCode: string
}

interface ArrayObjectSelectState {
  selectedMessage: MessageData | null
  setSelectedMessage: (message: MessageData | null) => void
}

const MessageSelect: React.FC<ArrayObjectSelectState> = ({ selectedMessage, setSelectedMessage }) => {
  const [messageData, setMessage] = useState<MessageData[]>([])

  useEffect(() => {
    const loadCountries = async () => {
      const response = await messageTemplateServices.findAll('')

      if (response) {
        const messageData = response.data as MessageData[]
        setMessage(
          messageData.map<MessageData>((item) => {
            return {
              id: item.id,
              name: item.name,
              type: item.type,
              content: item.content,
              languageCode: item.languageCode,
            }
          }),
        )
      } else {
        toast('An error occurred while loading the Message data.')
      }
    }
    loadCountries()
  }, [setSelectedMessage])

  return (
    <Select
      value={selectedMessage}
      onChange={(option: MessageData | null) => {
        setSelectedMessage(option)
      }}
      getOptionLabel={(option: MessageData) => option.type}
      getOptionValue={(option: MessageData) => option.id}
      options={messageData}
      isClearable={true}
      backspaceRemovesValue={true}
      placeholder={'Message'}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          zIndex: 3,
        }),
      }}
    />
  )
}

export default MessageSelect
