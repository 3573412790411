import { Button, Flex, Grid, Input } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

import { MdWork } from 'react-icons/md'

import DefaultLayout from '../../../../layouts/DefaultLayout'

// Services
import { interestingMatcheServices } from '../../../../services/common/interestingMatches/index'

// Components
import CustomInputForm from '../../../../components/customFormElements/CustomInputForm'

import GeneralCard from '../../../../components/cards/generalCard/GeneralCard'
import NavCard from '../../../../components/cards/navCard/NavigateCard'
import ProjectSelect from '../../../../components/selects/ProjectSelect'
import { useState } from 'react'

type InterestingMatcheFormData = {
  projectId: string
  homeTeam: string
  awayTeam: string
  homeTeamOdds: string
  awayTeamOdds: string
  drawOdds: string
}

const InterestingMatcheCreate = () => {
  const [selectedProject, setSelectedProject] = useState<any | null>(null)
  const navigate = useNavigate()

  const validationSchema = Yup.object().shape({
    homeTeam: Yup.string().required('Home Team is required'),
    awayTeam: Yup.string().required('Away Team is required'),
    homeTeamOdds: Yup.string().required('Home Team Odds is required'),
    awayTeamOdds: Yup.string().required('Away Team Odds is required'),
    drawOdds: Yup.string().required('Draw Odds is required'),
  })

  const handleOnSubmit = () => {
    handleSubmit(onSubmit)()
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<InterestingMatcheFormData>({
    resolver: yupResolver(validationSchema),
  })

  const onSubmit = (data: InterestingMatcheFormData) => {
    data.projectId = selectedProject.id
    console.log(data)
    interestingMatcheServices.create(data).finally(() => {
      navigate('/interesting-matche')
    })
  }

  return (
    <DefaultLayout>
      <NavCard icon={MdWork} pageName='Message Template' />
      <form onSubmit={handleSubmit(onSubmit)}>
        <GeneralCard title='Add Interesting Matche'>
          <Grid templateRows='repeat(2, 1fr)' templateColumns='repeat(6, 1fr)' gap={2} margin={'20px'}>
            <CustomInputForm
              title='Prject'
              children={<ProjectSelect selectedProject={selectedProject} setSelectedProject={setSelectedProject} />}
            />
            <CustomInputForm
              title='Home Team'
              children={<Input type='text' id='homeTeam' {...register('homeTeam')} />}
            />
            <CustomInputForm
              title='Away Team'
              children={<Input type='text' id='awayTeam' {...register('awayTeam')} />}
            />
            <CustomInputForm
              title='Home Team Odds'
              children={<Input type='text' id='homeTeamOdds' {...register('homeTeamOdds')} />}
            />
            <CustomInputForm
              title='Away Team Odds'
              children={<Input type='text' id='awayTeamOdds' {...register('awayTeamOdds')} />}
            />
            <CustomInputForm
              title='Draw Odds'
              children={<Input type='text' id='drawOdds' {...register('drawOdds')} />}
            />
          </Grid>
        </GeneralCard>
      </form>
      <Flex margin={'20px 0px 0px 40px'}>
        <Button colorScheme='red' size={'lg'} minWidth={'210px'} onClick={() => handleOnSubmit()} children={'Add'} />
      </Flex>
    </DefaultLayout>
  )
}
export default InterestingMatcheCreate
