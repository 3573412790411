import Select, { components, Props as SelectProps, ControlProps } from 'react-select'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Box } from '@chakra-ui/react'
import { debounce } from 'lodash'

import { userServices } from '../../services/users'

interface ResponseModel {
  id: string
  firstName: string
  lastName: string
  mainSocialMediaName: string
}

const Control = ({ children, ...props }: ControlProps<ResponseModel, false>) => (
  <div style={{ border: '1px solid black', position: 'relative' }}>
    <div
      style={{
        position: 'absolute',
        top: '-10px',
        marginLeft: '10px',
        background: 'white',
        padding: '0 5px',
        color: 'red',
        zIndex: '9',
      }}
    >
      Name, Phone, Email, @username
    </div>
    <components.Control {...props}>{children}</components.Control>
  </div>
)
const DropdownIndicator = () => null

const customComponents = {
  Control,
  DropdownIndicator,
}
type MySelectProps = SelectProps<ResponseModel, false>

function SearchSelect(props: MySelectProps) {
  const navigate = useNavigate()
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState<ResponseModel[]>()

  const loadOptions = debounce(async (inputValue) => {
    if (inputValue.length >= 3) {
      await userServices
        .queryGeneralSearch(inputValue)
        .then((response) => {
          const result = response.data as ResponseModel[]
          setOptions(
            result.map<ResponseModel>((item) => ({
              id: item.id,
              firstName: item.firstName,
              lastName: item.lastName,
              mainSocialMediaName: item.mainSocialMediaName,
            })),
          )
        })
        .catch((reason: any) => {})
    } else {
      toast('Minimum Three ')
    }
  }, 300) // 300ms delay

  useEffect(() => {
    if (inputValue) {
      loadOptions(inputValue)
    }
  }, [inputValue])

  const navigateDetail = (option: ResponseModel | null) => {
    if (option) navigate(`/user/${option.id}`)
  }

  return (
    <Box w='400px' margin='auto'>
      <Select
        options={options}
        isSearchable
        placeholder='Search ...'
        onInputChange={setInputValue}
        onChange={(option: ResponseModel | null) => {
          navigateDetail(option)
        }}
        getOptionLabel={(option: ResponseModel) => option.firstName + ' ' + option.lastName}
        getOptionValue={(option: ResponseModel) => option.id}
        {...props}
        components={customComponents}
      />
    </Box>
  )
}

export default SearchSelect
