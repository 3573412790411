import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { MdWork } from 'react-icons/md'
import { toast } from 'react-toastify'

import DefaultLayout from '../../../layouts/DefaultLayout'

// Services
import { messageTemplateServices } from '../../../services/common/messageTemplates/index'

// Components
import ActionButton from '../../../components/customFormElements/ActionButtons'
import GeneralCard from '../../../components/cards/generalCard/GeneralCard'
import NavCard from '../../../components/cards/navCard/NavigateCard'
import ProjectFilter from '../../../components/generalFilter/ProjectFilter'

const MessageTemplate = () => {
  const [messageTemplateData, setMessageTemplatesData] = useState<any[]>([])
  const [selectedProject, setSelectedProject] = useState<any | null>(null)
  const [inputValue, setInputValue] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    const projectId =
      selectedProject && selectedProject.id && selectedProject.id.length > 1
        ? selectedProject.id
        : '8c115a5d-749b-4f0f-9603-b05d2049fa19'

    messageTemplateServices.findAll(projectId).then((response) => {
      if (response) setMessageTemplatesData(response.data)
      else toast('An error occurred while loading the Message Template data.')
    })
  }, [selectedProject])

  const updateMessageTemplate = (id: string) => {
    navigate(`/message-template/update/${id}`)
  }

  const detailMessageTemplate = (id: string) => {
    navigate(`/message-template/${id}`)
  }

  const deleteMessageTemplate = (id: string) => {
    messageTemplateServices.remove(id).finally(() => {
      window.location.reload()
    })
  }

  return (
    <DefaultLayout>
      <NavCard icon={MdWork} pageName='Message Template' secondButton='Add Message Template' secondButtonLink='add' />
      <GeneralCard
        title=''
        children={<ProjectFilter setSearchInput={setInputValue} setSelectedProject={setSelectedProject} />}
      />
      <GeneralCard title='Message Template List' customColumn={{ base: 1, md: 1, lg: 1, '2xl': 1 }}>
        <TableContainer>
          <Table variant='simple' size={'sm'}>
            <Thead bg={'white'} position={'sticky'} top={'0px'} zIndex={1}>
              <Tr>
                <Th children={'type'} />
                <Th children={'name'} />
                <Th children={'content'} />
                <Th children={'language Code'} />
                <Th children={'Project'} />
                <Th children={'ACTION'} />
              </Tr>
            </Thead>
            {messageTemplateData.map((item) => {
              return (
                <Tbody paddingBottom={'20px'} key={item.id}>
                  <Tr>
                    <Td onClick={() => detailMessageTemplate(item.id)} cursor={'pointer'} children={item.type} />
                    <Td children={item.name} />
                    <Td children={`${item.content.slice(0, 10)}****`} />
                    <Td children={item.languageCode} />
                    <Td children={item.projectId} />
                    <Td
                      children={
                        <ActionButton
                          deleteFunction={deleteMessageTemplate}
                          id={item.id}
                          updateFunction={updateMessageTemplate}
                        />
                      }
                    />
                  </Tr>
                </Tbody>
              )
            })}
          </Table>
        </TableContainer>
      </GeneralCard>
    </DefaultLayout>
  )
}

export default MessageTemplate
