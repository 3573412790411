import { withLoggedIn, withLoggedOut } from './withLoggedIn'
import AccessDenied from '../pages/error/AccessDenied'
import { Route, Routes } from 'react-router-dom'
import _404 from '../pages/error/404'
import Dashboard from '../pages/dashboard/dashboard'

// User
import User from '../pages/users/users/index'
import UserDetail from '../pages/users/users/detail/index'
import UserCreate from '../pages/users/users/create/index'
import UserUpdate from '../pages/users/users/update/index'

// Project
import Project from '../pages/common/projects/index'
import ProjectDetail from '../pages/common/projects/detail/index'
import ProjectCreate from '../pages/common/projects/create/index'
import ProjectUpdate from '../pages/common/projects/update/index'

// Message Template
import MessageTemplate from '../pages/common/messageTemplates/index'
import MessageTemplateDetail from '../pages/common/messageTemplates/detail/index'
import MessageTemplateCreate from '../pages/common/messageTemplates/create/index'
import MessageTemplateUpdate from '../pages/common/messageTemplates/update/index'

// Message Template
import MailTemplate from '../pages/common/mailTemplates/index'
import MailTemplateDetail from '../pages/common/mailTemplates/detail/index'
import MailTemplateCreate from '../pages/common/mailTemplates/create/index'
import MailTemplateUpdate from '../pages/common/mailTemplates/update/index'

// Interesting Matche
import InterestingMatche from '../pages/common/interestingMatches/index'
import InterestingMatcheDetail from '../pages/common/interestingMatches/detail/index'
import InterestingMatcheCreate from '../pages/common/interestingMatches/create/index'
import InterestingMatcheUpdate from '../pages/common/interestingMatches/update/index'

// Language
import Language from '../pages/common/languages/index'
import LanguageDetail from '../pages/common/languages/detail/index'
import LanguageCreate from '../pages/common/languages/create/index'
import LanguageUpdate from '../pages/common/languages/update/index'

// Employee
import Employee from '../pages/manageEmployees/index'
import EmployeeDetail from '../pages/manageEmployees/detail/index'
import EmployeeCreate from '../pages/manageEmployees/create/index'
import EmployeeUpdate from '../pages/manageEmployees/update/index'

// Employee Call
import EmployeeCall from '../pages/employeeCalls/index'

import Login from '../pages/auth/login'

export const AuthRoute = (...args: Parameters<typeof Route>) => Route(...args)

export function AppRoutes() {
  return (
    <Routes>
      <Route path='/' element={withLoggedIn(Dashboard)()} />
      <Route path='/login' element={withLoggedOut(Login)()} />

      <Route path='/dashboard' element={withLoggedIn(Dashboard)()} />

      <Route path='/user' element={withLoggedIn(User)()} />
      <Route path='/user/:id' element={withLoggedIn(UserDetail)()} />
      <Route path='/user/update/:id' element={withLoggedIn(UserUpdate)()} />
      <Route path='/user/add' element={UserCreate()} />

      <Route path='/project' element={withLoggedIn(Project)()} />
      <Route path='/project/:id' element={withLoggedIn(ProjectDetail)()} />
      <Route path='/project/add' element={withLoggedIn(ProjectCreate)()} />
      <Route path='/project/update/:id' element={withLoggedIn(ProjectUpdate)()} />

      <Route path='/message-template' element={withLoggedIn(MessageTemplate)()} />
      <Route path='/message-template/:id' element={withLoggedIn(MessageTemplateDetail)()} />
      <Route path='/message-template/add' element={withLoggedIn(MessageTemplateCreate)()} />
      <Route path='/message-template/update/:id' element={withLoggedIn(MessageTemplateUpdate)()} />

      <Route path='/mail-template' element={withLoggedIn(MailTemplate)()} />
      <Route path='/mail-template/:id' element={withLoggedIn(MailTemplateDetail)()} />
      <Route path='/mail-template/add' element={withLoggedIn(MailTemplateCreate)()} />
      <Route path='/mail-template/update/:id' element={withLoggedIn(MailTemplateUpdate)()} />

      <Route path='/language' element={withLoggedIn(Language)()} />
      <Route path='/language/:id' element={withLoggedIn(LanguageDetail)()} />
      <Route path='/language/add' element={withLoggedIn(LanguageCreate)()} />
      <Route path='/language/update/:id' element={withLoggedIn(LanguageUpdate)()} />

      <Route path='/interesting-matche' element={withLoggedIn(InterestingMatche)()} />
      <Route path='/interesting-matche/:id' element={withLoggedIn(InterestingMatcheDetail)()} />
      <Route path='/interesting-matche/add' element={withLoggedIn(InterestingMatcheCreate)()} />
      <Route path='/interesting-matche/update/:id' element={withLoggedIn(InterestingMatcheUpdate)()} />

      <Route path='/manage-employee' element={withLoggedIn(Employee)()} />
      <Route path='/manage-employee/:id' element={withLoggedIn(EmployeeDetail)()} />
      <Route path='/manage-employee/add' element={withLoggedIn(EmployeeCreate)()} />
      <Route path='/manage-employee/update/:id' element={withLoggedIn(EmployeeUpdate)()} />

      <Route path='/employee-call' element={withLoggedIn(EmployeeCall)()} />

      <Route path='/access-denied' element={withLoggedIn(AccessDenied)()} />
      <Route path='*' element={withLoggedIn(Dashboard)()} />
    </Routes>
  )
}
