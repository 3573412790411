import { TableContainer, Table, Tbody, Thead, Td, Th, Tr } from '@chakra-ui/react'

import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

// Services
import { userMessageServices } from '../../../services/users/userMessage'

import LoadingComponent from '../../shareds/LoadingComponent'

// Components

const UserMessageTable = () => {
  const [employeeCallData, setEmployeeCallData] = useState<any[]>([])

  useEffect(() => {
    userMessageServices.dailyMessageCountPerAgent().then((response) => {
      if (response) {
        setEmployeeCallData(response.data)
      } else {
        toast('An error occurred while loading the  data.')
      }
    })
  }, [])

  if (!employeeCallData) {
    return <LoadingComponent />
  }

  return (
    <>
      <TableContainer maxH='20em' overflowY='auto'>
        <Table variant='simple' size={'sm'}>
          <Thead bg={'white'} position={'sticky'} top={'0px'} zIndex={1}>
            <Tr>
              <Th children={'Agent Name'} />
              <Th children={'Email'} />
              <Th children={'Message Count'} />
            </Tr>
          </Thead>
          {employeeCallData.map((item) => {
            return (
              <Tbody paddingBottom={'20px'} key={item.id}>
                <Tr>
                  <Td fontWeight={'bold'} children={item.firstName + ' ' + item.lastName} />
                  <Td children={item.lastName} />
                  <Td children={item.email} />
                  <Td children={item.message_count} />
                </Tr>
              </Tbody>
            )
          })}
        </Table>
      </TableContainer>
    </>
  )
}

export default UserMessageTable
