// Icons
import { MdDashboard } from 'react-icons/md'
import DefaultLayout from '../../layouts/DefaultLayout'

// Components
import NavCard from '../../components/cards/navCard/NavigateCard'
import UserMessageTable from '../../components/tables/userMessageTable/UserMessageTable'
import UserEmailTable from '../../components/tables/userEmailTable/UserEmailTable'
import MessageLogTable from '../../components/tables/messageLogTable/MessageLogTable'
import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { userMessageServices } from '../../services/users/userMessage'

const Dashboard = () => {
  const [selectedStartDate, setSelectedStartDate] = useState<string | null>(null)

  const exportMessageCount = async () => {
    const response = await userMessageServices.dailyMessageCountPerAgentExport()

    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(response.data)
    link.download = 'fileName'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const downloadCsvFile = (blobData: Blob, fileName: string) => {}

  useEffect(() => {
    const currentDate = new Date()
    const startDate = new Date(currentDate)
    startDate.setDate(currentDate.getDate() - 1)

    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]

    setSelectedStartDate(startDate.getDate().toString() + ' ' + months[startDate.getMonth()])
  }, [])

  return (
    <DefaultLayout>
      <NavCard icon={MdDashboard} pageName='Dashboard' key={'Dashboard'} />
      <Flex>
        <Box flex='48%' marginRight='2%'>
          <Text
            fontSize='18px'
            fontWeight='bold'
            margin='40px 20px 20px 20px'
            children={`Message Count - ${selectedStartDate}`}
          />
          {/* <Button
            onClick={() => {
              exportMessageCount()
            }}
          ></Button> */}

          <UserMessageTable />
        </Box>
        <Box flex='48%'>
          <Text
            fontSize={'18px'}
            margin={'40px 0px 20px 20px'}
            fontWeight={'bold'}
            children={`Mail Count - ${selectedStartDate}`}
          />
          <UserEmailTable />
        </Box>
      </Flex>

      <Flex marginBottom={'40px'}>
        <Box flex={'96%'}>
          <Text
            fontSize={'18px'}
            margin={'40px 0px 20px 20px'}
            fontWeight={'bold'}
            children={`Message Log - ${selectedStartDate}`}
          />
          <MessageLogTable />
        </Box>
      </Flex>
    </DefaultLayout>
  )
}

export default Dashboard
