import React, { useEffect } from 'react'
import Select from 'react-select'

import { EmployeeType } from '../../enums/employee-type'

interface ArrayObjectSelectState {
  selectedEmployeeType: EmployeeType | null
  setSelectedEmployeeType: (userType: EmployeeType | null) => void
  type?: string
}

const EmployeeTypeSelect: React.FC<ArrayObjectSelectState> = ({
  selectedEmployeeType,
  setSelectedEmployeeType,
  type,
}) => {
  const [userTypes, setEmployeeTypes] = React.useState<EmployeeType[]>([])

  useEffect(() => {
    setEmployeeTypes(Object.values(EmployeeType))
  }, [])

  useEffect(() => {
    if (type && userTypes.length > 0) {
      const userType = userTypes.find((item) => item === type)
      if (userType) setSelectedEmployeeType(userType)
    }
  }, [type, userTypes])

  return (
    <Select
      value={
        selectedEmployeeType
          ? {
              value: selectedEmployeeType,
              label: selectedEmployeeType?.toUpperCase(),
            }
          : null
      }
      onChange={(option: any) => {
        setSelectedEmployeeType(option ? option.value : null)
      }}
      getOptionLabel={(option: any) => option.label}
      getOptionValue={(option: any) => option.value}
      options={userTypes.map((type) => ({
        value: type,
        label: type.toUpperCase(),
      }))}
      isClearable={true}
      backspaceRemovesValue={true}
      placeholder={'TYPE'}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          zIndex: 3,
        }),
      }}
    />
  )
}

export default EmployeeTypeSelect
