import { Box, Button, Flex, Grid, Input, InputGroup, InputLeftAddon } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as Yup from 'yup'

// Services
import { userPhoneServices } from '../../../../services/users/userPhone/index'

// Components
import CustomInputForm from '../../../../components/customFormElements/CustomInputForm'
import LoadingComponent from '../../../../components/shareds/LoadingComponent'
import CountrySelect from '../../../../components/selects/CountrySelect'

type UserPhoneFormData = {
  userId: string
  country: string
  countryCode: string
  phoneNumber: string
}

const UserPhoneUpdate = (props: { id: string; closeModal: () => void }) => {
  const [selectedCountry, setSelectedCountry] = useState<any | null>(null)
  const [userPhoneData, setUserPhoneData] = useState<any | null>(null)
  const id = location.pathname.split('/user/')[1]

  const validationSchema = Yup.object().shape({
    phoneNumber: Yup.string().required('Phone Number is required'),
  })

  useEffect(() => {
    userPhoneServices.findOne(props.id).then((response) => {
      if (response) {
        setUserPhoneData(response.data)
      }
    })
  }, [])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserPhoneData({
      ...userPhoneData,
      [event.target.name]: event.target.value,
    })
  }

  const handleOnSubmit = () => {
    handleSubmit(onSubmit)()
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<UserPhoneFormData>({
    resolver: yupResolver(validationSchema),
  })

  const onSubmit = (data: UserPhoneFormData) => {
    if (!data.countryCode && !selectedCountry?.alpha2Code) {
      toast('Country is required')
      return
    }

    data.countryCode = data.countryCode ?? selectedCountry?.alpha2Code
    data.country = data.country ?? selectedCountry?.name
    data.userId = id

    userPhoneServices.update(props.id, data).finally(() => {
      props.closeModal()
    })
  }

  if (!userPhoneData) {
    return <LoadingComponent />
  }

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid templateRows='repeat(1, 1fr)' templateColumns='repeat(4, 1fr)' gap={4} margin={'20px'}>
          <CustomInputForm
            title='Country'
            children={
              <CountrySelect
                selectedCountry={selectedCountry}
                setSelectedCountry={setSelectedCountry}
                selectCountry={userPhoneData.countryCode}
              />
            }
          />
          <CustomInputForm
            title='Phone Number'
            children={
              <InputGroup>
                <InputLeftAddon children={selectedCountry ? selectedCountry.callingCodes : ''} />
                <Input
                  type='tel'
                  id='phoneNumber'
                  {...register('phoneNumber')}
                  placeholder='Phone Number'
                  value={userPhoneData.phoneNumber}
                  onChange={handleInputChange}
                />
              </InputGroup>
            }
          />
        </Grid>
      </form>
      <Flex width={'full'} ml={'20px'}>
        <Button colorScheme='red' size={'sm'} onClick={() => handleOnSubmit()} children={'Update'} />
      </Flex>
    </Box>
  )
}
export default UserPhoneUpdate
