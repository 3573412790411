import { Button, Flex, Grid, GridItem, Input } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

import { FaLanguage } from 'react-icons/fa'

import DefaultLayout from '../../../../layouts/DefaultLayout'

// Services
import { messageTemplateServices } from '../../../../services/common/messageTemplates/index'

// Components
import CustomInputForm from '../../../../components/customFormElements/CustomInputForm'
import LoadingComponent from '../../../../components/shareds/LoadingComponent'
import GeneralCard from '../../../../components/cards/generalCard/GeneralCard'
import NavCard from '../../../../components/cards/navCard/NavigateCard'
import LanguageSelect from '../../../../components/selects/LanguageSelect'
import MessageTypeSelect from '../../../../components/selects/MessageTypeSelect'
import { Editor } from '@tinymce/tinymce-react'

type MessageTemplateFormData = {
  type: string
  name: string
  content: string
  languageCode: string
}

const MessageTemplateUpdate = () => {
  const [messageTemplateData, setMessageTemplateData] = useState<any | null>(null)
  const [selectedLanguage, setSelectedLanguage] = useState<any | null>(null)
  const [selectedMessageType, setSelectedMessageType] = useState<any | null>(null)
  const [editorData, setEditorData] = useState<any>()

  const navigate = useNavigate()

  const id = location.pathname.split('/message-template/update/')[1]

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
  })

  const handleEditorChange = (content: string, editor: any) => {
    setEditorData(content)
  }

  useEffect(() => {
    messageTemplateServices.findOne(id).then((response) => {
      if (response) {
        setMessageTemplateData(response.data)
        setEditorData(response.data.content)
        setSelectedMessageType(response.data.type)
      }
    })
  }, [])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessageTemplateData({
      ...messageTemplateData,
      [event.target.name]: event.target.value,
    })
  }

  const handleOnSubmit = () => {
    handleSubmit(onSubmit)()
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<MessageTemplateFormData>({
    resolver: yupResolver(validationSchema),
  })

  const onSubmit = (data: MessageTemplateFormData) => {
    data.type = selectedMessageType
    data.languageCode = selectedLanguage.code
    data.content = editorData
    messageTemplateServices.update(id, data).finally(() => {
      navigate('/message-template')
    })
  }

  if (!messageTemplateData) {
    return <LoadingComponent />
  }

  return (
    <DefaultLayout>
      <NavCard icon={FaLanguage} pageName='MessageTemplate' />
      <form onSubmit={handleSubmit(onSubmit)}>
        <GeneralCard title='Update MessageTemplate'>
          <Grid templateRows='repeat(1, 1fr)' templateColumns='repeat(6, 1fr)' gap={4} margin={'20px'}>
            <CustomInputForm
              title='Type'
              children={
                <MessageTypeSelect
                  selectedMessageType={selectedMessageType}
                  setSelectedMessageType={setSelectedMessageType}
                  type={messageTemplateData.type}
                />
              }
            />

            <CustomInputForm
              title='Language'
              children={
                <LanguageSelect
                  selectedLanguage={selectedLanguage}
                  setSelectedLanguage={setSelectedLanguage}
                  code={messageTemplateData.languageCode}
                />
              }
            />
            <CustomInputForm
              error={errors.name}
              title='Name'
              children={
                <Input
                  bg={'white'}
                  type='text'
                  id='name'
                  {...register('name')}
                  value={messageTemplateData.name}
                  onChange={handleInputChange}
                />
              }
            />
            <GridItem
              colSpan={6}
              children={
                <Editor
                  apiKey='uhsd8cs3nsvcr8l0t1nwi8ix65n1p3mb21708bl77jr8wh9d'
                  init={{
                    skin: 'snow',
                    icons: 'thin',
                    height: 900,
                    menubar: true,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen textcolor ',
                      'insertdatetime media table paste code help wordcount',
                      'code',
                    ],

                    toolbar:
                      'undo redo | accordion accordionremove | blocks fontfamily fontsize | bold italic underline strikethrough | align numlist bullist | link image | table media | lineheight outdent indent| forecolor backcolor removeformat | charmap emoticons | code fullscreen preview | save print | pagebreak anchor codesample | ltr rtl',
                  }}
                  value={editorData ? editorData : ''}
                  onEditorChange={handleEditorChange}
                />
              }
            />
          </Grid>
        </GeneralCard>
      </form>
      <Flex margin={'20px 0px 0px 40px'}>
        <Button colorScheme='red' size={'lg'} minWidth={'210px'} onClick={() => handleOnSubmit()} children={'Update'} />
      </Flex>
    </DefaultLayout>
  )
}
export default MessageTemplateUpdate
