import { axiosInstance } from '../axios'

export const messageLogServices = {
  create: (requestModel: any) => {
    return axiosInstance.post('message-response', requestModel)
  },
  update: (id: string, requestModel: any) => {
    return axiosInstance.put(`message-response/${id}`, requestModel)
  },
  findAll: () => {
    return axiosInstance.get('message-response')
  },
  dailyMessageLog: () => {
    return axiosInstance.get('message-response/dailyMessageLog')
  },
  findOne: (id: string) => {
    return axiosInstance.get(`message-response/${id}`)
  },
  findByUserId: (id: string) => {
    return axiosInstance.get(`message-response/findByUserId/${id}`)
  },
  findByEmployeeId: (id: string) => {
    return axiosInstance.get(`message-response/findByEmployeeId/${id}`)
  },
  remove: (id: string) => {
    return axiosInstance.delete(`message-response/${id}`)
  },
}
