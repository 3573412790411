import React, { useEffect } from 'react'
import Select from 'react-select'

import { MailType } from '../../enums/mail-type'

interface ArrayObjectSelectState {
  selectedMailType: MailType | null
  setSelectedMailType: (mailType: MailType | null) => void
  type?: string
}

const MailTypeSelect: React.FC<ArrayObjectSelectState> = ({ selectedMailType, setSelectedMailType, type }) => {
  const [mailTypes, setMailTypes] = React.useState<MailType[]>([])

  useEffect(() => {
    setMailTypes(Object.values(MailType))

    if (type) {
      const mailType = mailTypes.find((item) => item === type)

      if (mailType) setSelectedMailType(mailType)
    }
  }, [type, setMailTypes])

  return (
    <Select
      value={
        selectedMailType
          ? {
              value: selectedMailType,
              label: selectedMailType?.toUpperCase(),
            }
          : null
      }
      onChange={(option: any) => {
        setSelectedMailType(option ? option.value : null)
      }}
      getOptionLabel={(option: any) => option.label}
      getOptionValue={(option: any) => option.value}
      options={mailTypes.map((type) => ({
        value: type,
        label: type.toUpperCase(),
      }))}
      isClearable={true}
      backspaceRemovesValue={true}
      placeholder={'TYPE'}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          zIndex: 3,
        }),
      }}
    />
  )
}

export default MailTypeSelect
