import { GridItem, Text, Card, CardBody, Heading } from '@chakra-ui/react'

export default function DetailCard(props: { upperText: string; lowerText: string; customColspan?: number }) {
  const defaultColSpan = 2
  const colSpan = props.customColspan || defaultColSpan
  return (
    <>
      <GridItem
        colSpan={colSpan}
        children={
          <Card>
            <CardBody>
              <Heading size='xs' textTransform='uppercase' children={props.upperText} />
              <Text pt='2' fontSize='sm' children={props.lowerText} style={{ whiteSpace: 'pre-line' }} />
            </CardBody>
          </Card>
        }
      />
    </>
  )
}
