import { Button, Flex, Grid, Input } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useState } from 'react'
import * as Yup from 'yup'

// Layouts
import DefaultLayout from '../../../layouts/DefaultLayout'

// Icons
import { MdOutlineManageAccounts } from 'react-icons/md'

// Services
import { employeeServices } from '../../../services/employees/index'

// Components
import CustomInputForm from '../../../components/customFormElements/CustomInputForm'
import EmployeeTypeSelect from '../../../components/selects/EmployeeTypeSelect'
import GeneralCard from '../../../components/cards/generalCard/GeneralCard'
import NavCard from '../../../components/cards/navCard/NavigateCard'
import ProjectSelect from '../../../components/selects/ProjectSelect'
import CountrySelect from '../../../components/selects/CountrySelect'
import CustomCheckbox from '../../../components/customFormElements/CustomCheckbox'

type EmployeeFormData = {
  firstName: string
  lastName: string
  type: string
  email: string
  countryId: string
  country: string
  isRandomPassword: number
  projectId: string
  password: string
  code: string
  dialpadEmployeeId: string
}

const EmployeeCreate = () => {
  const [selectedEmployeeType, setSelectedEmployeeType] = useState<any | null>(null)
  const [selectedProject, setSelectedProject] = useState<any | null>(null)
  const [selectedCountry, setSelectedCountry] = useState<any | null>(null)
  const [isChecked, setChecked] = useState(false)
  const [isRandomPasswordData, setIsRandomPassword] = useState(0)

  const navigate = useNavigate()

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    email: Yup.string().email().required('Email is required'),
    password: Yup.string().required('Password is required'),
    code: Yup.string().required('Code is required'),
  })

  const handleOnSubmit = () => {
    handleSubmit(onSubmit)()
  }

  const handleCheckChange = (newCheckState: boolean) => {
    if (newCheckState) setIsRandomPassword(1)
    else setIsRandomPassword(0)
    setChecked(newCheckState)
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<EmployeeFormData>({
    resolver: yupResolver(validationSchema),
  })

  const onSubmit = (data: EmployeeFormData) => {
    if (!selectedEmployeeType) {
      toast('Type is required')
      return
    }

    if (!selectedCountry) {
      toast('Country is required')
      return
    }

    data.type = selectedEmployeeType
    data.projectId = selectedProject.id
    data.countryId = selectedCountry.id
    data.country = selectedCountry.name
    data.isRandomPassword = isRandomPasswordData

    employeeServices
      .create(data)
      .then((response) => {
        if (response) {
          navigate(`/manage-employee`)
        }
      })
      .catch((error) => {})
  }

  return (
    <DefaultLayout>
      <NavCard icon={MdOutlineManageAccounts} pageName='Employee' />

      <form onSubmit={handleSubmit(onSubmit)}>
        <GeneralCard title='Add Employee'>
          <Grid templateRows='repeat(4, 1fr)' templateColumns='repeat(4, 1fr)' gap={4} margin={'20px'}>
            <CustomInputForm
              title='First Name'
              error={errors.firstName}
              children={<Input type='text' id='firstName' {...register('firstName')} />}
            />
            <CustomInputForm
              title='Last Name'
              error={errors.lastName}
              children={<Input type='text' id='lastName' {...register('lastName')} />}
            />
            <CustomInputForm
              title='Country'
              children={
                <CountrySelect
                  selectCountry=''
                  selectedCountry={selectedCountry}
                  setSelectedCountry={setSelectedCountry}
                />
              }
            />
            <CustomInputForm
              title='Type'
              error={errors.type}
              children={
                <EmployeeTypeSelect
                  selectedEmployeeType={selectedEmployeeType}
                  setSelectedEmployeeType={setSelectedEmployeeType}
                  type=''
                />
              }
            />
            <CustomInputForm
              title='Email'
              error={errors.email}
              children={<Input type='email' id='email' {...register('email')} />}
            />
            <CustomInputForm
              title='Password'
              customColSpan={1}
              children={<Input type='password' id='password' {...register('password')} />}
            />
            <CustomInputForm
              title='Random Password'
              customColSpan={1}
              children={
                <CustomCheckbox
                  defaultChecked={isChecked}
                  onCheckChange={handleCheckChange}
                  name={''}
                  register={register}
                  registerName='s'
                />
              }
            />
            <CustomInputForm
              title='Project'
              children={
                <ProjectSelect selectedProject={selectedProject} setSelectedProject={setSelectedProject} id='' />
              }
            />
            <CustomInputForm
              title='Code'
              error={errors.email}
              children={<Input type='code' id='code' {...register('code')} />}
            />
            <CustomInputForm
              title='Dialpad Id'
              error={errors.dialpadEmployeeId}
              children={<Input type='text' id='dialpadEmployeeId' {...register('dialpadEmployeeId')} />}
            />
          </Grid>
        </GeneralCard>
      </form>
      <Flex margin={'20px 0px 40px 40px'}>
        <Button colorScheme='red' size={'lg'} minWidth={'210px'} onClick={() => handleOnSubmit()} children={'Add'} />
      </Flex>
    </DefaultLayout>
  )
}

export default EmployeeCreate
