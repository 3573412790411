import { Button, Flex, Grid, Input } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

import { MdWork } from 'react-icons/md'

import DefaultLayout from '../../../../layouts/DefaultLayout'

// Services
import { interestingMatcheServices } from '../../../../services/common/interestingMatches/index'

// Components
import CustomInputForm from '../../../../components/customFormElements/CustomInputForm'

import GeneralCard from '../../../../components/cards/generalCard/GeneralCard'
import NavCard from '../../../../components/cards/navCard/NavigateCard'
import { useEffect, useState } from 'react'
import LoadingComponent from '../../../../components/shareds/LoadingComponent'

type InterestingMatcheFormData = {
  homeTeam: string
  awayTeam: string
  homeTeamOdds: string
  awayTeamOdds: string
  drawOdds: string
}

const InterestingMatcheUpdate = () => {
  const [interestingMatcheData, setInterestingMatcheData] = useState<any | null>(null)
  const id = location.pathname.split('/interesting-matche/update/')[1]
  const navigate = useNavigate()

  const validationSchema = Yup.object().shape({
    homeTeam: Yup.string().required('Home Team is required'),
    awayTeam: Yup.string().required('Away Team is required'),
    homeTeamOdds: Yup.string().required('Home Team Odds is required'),
    awayTeamOdds: Yup.string().required('Away Team Odds is required'),
    drawOdds: Yup.string().required('Draw Odds is required'),
  })

  useEffect(() => {
    interestingMatcheServices.findOne(id).then((response) => {
      if (response) {
        setInterestingMatcheData(response.data)
      }
    })
  }, [])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInterestingMatcheData({
      ...interestingMatcheData,
      [event.target.name]: event.target.value,
    })
  }

  const handleOnSubmit = () => {
    handleSubmit(onSubmit)()
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<InterestingMatcheFormData>({
    resolver: yupResolver(validationSchema),
  })

  const onSubmit = (data: InterestingMatcheFormData) => {
    interestingMatcheServices.update(id, data).finally(() => {
      navigate('/interesting-matche')
    })
  }

  if (!interestingMatcheData) {
    return <LoadingComponent />
  }

  return (
    <DefaultLayout>
      <NavCard icon={MdWork} pageName='Message Template' />
      <form onSubmit={handleSubmit(onSubmit)}>
        <GeneralCard title='Add Interesting Matche'>
          <Grid templateRows='repeat(2, 1fr)' templateColumns='repeat(6, 1fr)' gap={2} margin={'20px'}>
            <CustomInputForm
              title='Home Team'
              children={
                <Input
                  type='text'
                  id='homeTeam'
                  {...register('homeTeam')}
                  value={interestingMatcheData.homeTeam}
                  onChange={handleInputChange}
                />
              }
            />
            <CustomInputForm
              title='Away Team'
              children={
                <Input
                  type='text'
                  id='awayTeam'
                  {...register('awayTeam')}
                  value={interestingMatcheData.awayTeam}
                  onChange={handleInputChange}
                />
              }
            />
            <CustomInputForm
              title='Home Team Odds'
              children={
                <Input
                  type='text'
                  id='homeTeamOdds'
                  {...register('homeTeamOdds')}
                  value={interestingMatcheData.homeTeamOdds}
                  onChange={handleInputChange}
                />
              }
            />
            <CustomInputForm
              title='Away Team Odds'
              children={
                <Input
                  type='text'
                  id='awayTeamOdds'
                  {...register('awayTeamOdds')}
                  value={interestingMatcheData.awayTeamOdds}
                  onChange={handleInputChange}
                />
              }
            />
            <CustomInputForm
              title='Draw Odds'
              children={
                <Input
                  type='text'
                  id='drawOdds'
                  {...register('drawOdds')}
                  value={interestingMatcheData.drawOdds}
                  onChange={handleInputChange}
                />
              }
            />
          </Grid>
        </GeneralCard>
      </form>
      <Flex margin={'20px 0px 0px 40px'}>
        <Button colorScheme='red' size={'lg'} minWidth={'210px'} onClick={() => handleOnSubmit()} children={'Update'} />
      </Flex>
    </DefaultLayout>
  )
}
export default InterestingMatcheUpdate
