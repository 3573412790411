import { Table, TableContainer, Tbody, Td, Th, Thead, Tr, Link, useDisclosure } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import UserMessageDetail from './detail'

// Services
import { userMessageServices } from '../../../services/users/userMessage/index'

// Components
import CustomModal from '../../../components/modal/CustomModal'

const UserMessage = () => {
  const [userMessageData, setUserMessagesData] = useState<any[]>([])
  const [userMessageId, setUserMessageId] = useState<string>()

  const { isOpen: isOpenDetail, onOpen: onOpenDetail, onClose: onCloseDetail } = useDisclosure()

  const id = location.pathname.split('/user/')[1]

  useEffect(() => {
    userMessageServices.findByUserId(id).then((response) => {
      if (response) {
        setUserMessagesData(response.data)
      } else {
        toast('An error occurred while loading the User Message data.')
      }
    })
  }, [id])

  const setUserMessage = () => {
    userMessageServices.findByUserId(id).then((response) => {
      if (response) {
        setUserMessagesData(response.data)
      } else {
        toast('An error occurred while loading the User Message data.')
      }
    })
  }

  const detailUserMessage = (noteId: string) => {
    setUserMessageId(noteId)
    onOpenDetail()
  }

  return (
    <>
      <TableContainer maxH='20em' overflowY='auto'>
        <Table variant='simple' size={'sm'}>
          <Thead bg={'white'} position={'sticky'} top={'0px'} zIndex={1}>
            <Tr>
              <Th children={'message Content'} />
              <Th children={'status'} />
              <Th children={'message Type'} />
              <Th children={'employee'} />
              <Th children={'Created Date'} />
            </Tr>
          </Thead>
          {userMessageData.map((item) => {
            return (
              <Tbody paddingBottom={'20px'} key={item.id}>
                <Tr>
                  <Td
                    onClick={() => detailUserMessage(item.id)}
                    cursor={'pointer'}
                    color={'blue'}
                    children={<Link children={`${item.messageContent.slice(0, 10)}****`} />}
                  />
                  <Td children={item.status} />
                  <Td children={item.messageType} />
                  <Td children={item.employeeName} />
                  <Td children={item.createdDate.slice(0, 10)} />
                </Tr>
              </Tbody>
            )
          })}
        </Table>
      </TableContainer>

      {
        <CustomModal
          title='User Message Detail'
          isOpen={isOpenDetail}
          onClose={onCloseDetail}
          key={'User_Message_Detail'}
          children={<UserMessageDetail id={userMessageId ? userMessageId : ''} />}
        />
      }
    </>
  )
}

export default UserMessage
