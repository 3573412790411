import { axiosInstance } from '../axios/index'

export const employeeServices = {
  create: (requestModel: any) => {
    return axiosInstance.post('employees', requestModel)
  },
  update: (id: string, requestModel: any) => {
    return axiosInstance.put(`employees/${id}`, requestModel)
  },
  findAll: (searchTag: string, page: number, limit: number, countryId: string, projectId: string) => {
    return axiosInstance.get(
      `employees?searchTag=${searchTag}&page=${page}&limit=${limit}&countryId=${countryId}&projectId=${projectId}`,
    )
  },
  findOne: (id: string) => {
    return axiosInstance.get(`employees/${id}`)
  },
  remove: (id: string) => {
    return axiosInstance.delete(`employees/${id}`)
  },
}
